import { useContext, useMemo } from "react";
import { RubricType } from "src/api/goodpoint/Const";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import useFetchRubric from "src/api/goodpoint/useFetchRubric";
import { RubricStructure } from "src/pages/goodpoint/assessment/tabs/rubric/structure/RubricStructure";

export default function RubricContent({ type }) {
    const {assessmentObject} = useContext(AssessmentContext);

    const fetchOriginalRubric = useFetchRubric(assessmentObject.id, RubricType.ORIGINAL);
    const fetchEnhancedRubric = useFetchRubric(assessmentObject.id, RubricType.ENHANCED);

    const rubric = useMemo(() => {
        let func;
        switch (type) {
            case RubricType.ORIGINAL: func = fetchOriginalRubric; break;
            case RubricType.ENHANCED: default: func = fetchEnhancedRubric; break;
        }
        var result = func();
        if (!result) return null;
        return result;
    }, [fetchEnhancedRubric, fetchOriginalRubric, type]);

    return (
        (rubric != null)
            ? <RubricStructure rubric={rubric} enhanced={type === RubricType.ENHANCED}/>
            : <p>Loading...</p>
    )
}