
function Feature({icon, title, description}) {
    return (
        <div className="flex-col justify-start items-start text-start">
            <div className="rounded bg-gray-100 p-2 text-orange-400">
                {icon}
            </div>
            <h3 className="pt-2 pb-1 text-base sm:text-lg font-bold kanit-regular">
                {title}
            </h3>
            <p className="text-gray-600 text-xs sm:text-base w-[90%]">
                {description}
            </p>
        </div>
    )
}

export default Feature;