import "./Products.css"
import goodpointPipelineImg from 'src/assets/home/goodpoint-pipeline.jpg'
import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import { Link } from 'react-router-dom';
import heroImg from 'src/assets/home/goodpoint-hero.png';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';
import { Cpu, DashboardSpeed, FontQuestion, Group, OpenBook, Sparks, TestTube, Timer } from "iconoir-react";
import Feature from "src/pages/home/components/Feature";

const demoPlaceholder = "I would like to set up a short meeting to find out more about GoodPoint. Please reach out to me by email."

function GoodPointProduct() {
    return (
        <div>
            <ProductHeroSection 
                title="GoodPoint" 
                subtitle="Automated grading with human-level precision and feedback" 
                image={heroImg} 
                CTASection={CTASection}
            />
            <FeaturesSection />
            <HowItWorksSection />
            <TryNowSection />
        </div>
    );
}

const CTASection = () => {
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className="clickable bg-orange-300 hover:bg-orange-300 text-black outline outline-2 rounded shadow-xl">
                    Request a demo
                </button>
            </ContactPopup>
            <Link to="/dashboard">
                <button className="bg-transparent hover:bg-orange-400 text-black outline outline-2 rounded">
                    Sign up
                </button>
            </Link>
        </div>
    )
}

function FeaturesSection() {
    return (
        <div className="bg-transparent md:py-12 py-8 md:px-8 px-0">
            <div className="container mx-auto">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4 md:gap-y-16 gap-y-8">
                <Feature 
                    icon={<Group size={32}/>} 
                    title={"Personalised feedback for every student"} 
                    description={"Question by question feedback gives students the necessary resources to learn from their mistakes."}
                />
                <Feature 
                    icon={<Timer size={32}/>} 
                    title={"More time for educators"} 
                    description={"GoodPoint allows educators to focus more on teaching and less on marking."}
                />
                <Feature 
                    icon={<TestTube size={32}/>} 
                    title={"Pilot programs and partnerships"} 
                    description={"We are currently engaged in pilot programs with educators from all over the world to improve our product."}
                />
                <Feature 
                    icon={<DashboardSpeed size={32}/>} 
                    title={"Fast marking with AI"} 
                    description={"No more waiting weeks for results, only to have forgotten the content."}
                />
                </div>
            </div>
        </div>
    );
}

function HowItWorksSection() {
    return (
        <div className="flex-col justify-start items-start py-4">
            <div>
                <h2 className="py-2 font-bold text-2xl kanit-regular">
                    How GoodPoint Works
                </h2>
                <p className="text-gray-600 font-bold text-sm pb-1">
                    Not just another GPT-wrapper.
                </p>
                <p className="text-gray-600 text-sm">
                    GoodPoint is capable of evaluating STEM subjects for pre-university, undergraduate and postgraduate courses using a robust pipeline.
                </p>
            </div>
            <div className="grid grid-cols-2 gap-6 py-8">
                <Feature 
                    icon={<Cpu size={24}/>}
                    title="Pre-processing" 
                    description="Transformations are applied to the raw scan of the exam paper." 
                />
                <Feature
                    icon={<FontQuestion size={24}/>}
                    title="OCR Extraction"
                    description="Our sophisticated OCR pipeline extracts the paper content page by page."
                />
                <Feature 
                    icon={<Sparks size={24}/>}
                    title="Rubric Enhancement" 
                    description="The rubric provided is enhanced with our own solver and the student answers are evaluated based on this rubric."
                />
                <Feature
                    icon={<OpenBook size={24}/>}
                    title="Marking and Feedback" 
                    description="The final result is an automatically graded exam with customized feedback for the students."
                />
            </div>
            <div className='py-6'>
                <img src={goodpointPipelineImg} alt="GoodPoint Pipeline" className="w-full"/>
            </div>
            <div className="mx-auto mt-2 w-full max-w-[800px] aspect-video rounded">
                <ReactPlayer 
                    url="https://www.youtube.com/watch?v=TlNLRbWxSB4"
                    width={"100%"}
                    height={"100%"}
                    controls
                />
            </div>
        </div>
    );
}

function TryNowSection() {
    return (
        <div className="flex-col justify-start items-start py-12 product-try-now">
            <div>
                <h2 className="py-2 font-bold text-2xl kanit-regular">
                    Try GoodPoint Now
                </h2>
                <span className="text-gray-600 text-sm">
                    Request a demo or sign up and be notified when GoodPoint is available.
                </span>
            </div>
            <div className="flex-row justify-content-center py-4 gap-4">
                <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                    <button className="bg-orange-300 hover:bg-orange-400 text-black outline outline-2 rounded">
                        Request a demo
                    </button>
                </ContactPopup>
                <Link to="/dashboard">
                <button className="bg-transparent hover:bg-orange-400 text-black outline outline-2 rounded">
                    Sign up
                </button>
                </Link>
            </div>
        </div>
    )
}

export default GoodPointProduct;