import Divider from "src/components/layout/Divider";

import "./StructureSection.css";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { NavArrowDown, NavArrowUp } from "iconoir-react";


export function SectionHeading({ heading, style={} }) {
    return <p 
        className={`font-italic user-select-none font-size-large`}
        style={style}
    >
        {heading}
    </p>
}

export function AiGenerated() {
    return <p
        className={
            `ai-generated fg-orange-dark font-size-medium user-select-none`
        }
    >
        (Generated with AI)
    </p>
}

export default function StructureSection({ heading, headingControls=null, aiGenerated=false, initiallyExpanded=true, children }) {
    const { isExpanded, toggle } = useExpandCollapse(initiallyExpanded);

    return (
        <div className="structure-section flex-col gap-small">
            <div className="flex-row justify-content-space-between gap-small">
                <div 
                    className="flex-row cursor-pointer fill-width align-center justify-content-space-between" 
                    onClick={toggle}
                >
                    <div className="flex-row gap-mid align-baseline">
                        <SectionHeading heading={heading} />
                        {
                            aiGenerated && <AiGenerated />
                        }
                    </div>
                </div>
                {
                    isExpanded && 
                        <div className="section-heading-controls">
                            {headingControls}
                        </div>
                }
                <div 
                    className="cursor-pointer align-center"
                    onClick={toggle}
                >
                    {
                        isExpanded ? <NavArrowDown /> : <NavArrowUp />
                    }
                </div>
            </div>
            <div className="structure-section-content">
                { isExpanded && children }
            </div>
            <Divider lineColour='rgba(0, 0, 0, 0.3)' shadowColour='rgba(0, 0, 0, 0.20)'/>
        </div>
    )
}