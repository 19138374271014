import Loading from "src/components/ui/Loading";
import { ExamStructure} from "src/pages/goodpoint/assessment/components/structure/ExamStructure";

import "./RubricStructure.css";
import { RubricItem } from "src/pages/goodpoint/assessment/tabs/rubric/structure/RubricItem";


export function RubricStructure({ rubric, enhanced=false }) {
    function RubricHeader({ structureMap }) {
        const numQuestions = Object.keys(structureMap).length;
        const totalMarks = 
            Object
                .values(structureMap)
                .reduce((acc, q) => q.mark ? acc + q.mark : acc, 0);

        return <div className="flex-row align-center gap-large">
            <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""}</p>
            <p>Total Marks: <b>{totalMarks}</b></p>
        </div>
    }

    if (!rubric) {
        return <Loading />
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                structureMap={rubric}
                HeaderComponent={RubricHeader}
                ItemComponent={RubricItem}
                itemCommonProps={{ enhanced }}
            />
        </div>
    )
}