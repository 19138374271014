import { createContext } from "react";
import { StructureItem } from "src/pages/goodpoint/assessment/components/structure/StructureItem";

export const ExamStructureContext = createContext({
    structureMap: {},
    ItemComponent: StructureItem,
    isSelectionMode: false,
    itemCommonProps: {},
    searchTerms: [],
    clearSearch: () => {},
    searchVisibility: {}
})