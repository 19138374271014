import useCallApi from "src/api/useCallApi";
import { useEffect } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { getAssessmentDescs, updateLocalAssessments } from "src/storage/AssessmentDesc";
import { useState } from "react";

export default function useAssessmentsList() {
    // Don't use useMemoFetch here because we want to query the server every time we render
    const callApi = useCallApi();
    const [assessments, setAssessments] = useState(getAssessmentDescs());
    const [queriedServer, setQueriedServer] = useState(false);

    useEffect(() => {
        if (queriedServer) return;
        callApi("GET", GoodPointApi.ListAssessments)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const n_assessments = updateLocalAssessments(body.exams)
                    setAssessments(n_assessments);
                    setQueriedServer(true);
                });
            } else {
                console.error("Failed to fetch assessments: " + response.status + " " + response.statusText);
            }
        });
    }, [callApi, queriedServer, setAssessments]);

    return {assessments, setAssessments, queriedServer}
}