import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";
import "./ReportContainer.css";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Plot from "react-plotly.js";
import useResetScroll from "src/hooks/useResetScroll";

const Loading = () => {

    const LoadingTextItem = ({ lines }) => {
        lines = lines || 4;
        return (
            <div className="loading-item">
                <div className="loading-header"></div>
                {
                    Array.from({length: lines - 1}, (_, index) => (
                        <div key={index} className="loading-text-line"></div>
                    ))
                }
                <div className="loading-text-line short"></div>
            </div>
        );
    };

    const LoadingTableItem = ({rows, cols}) => {
        rows = rows || 5;
        cols = cols || 5;
        return (
            <div className="loading-item">
                <div className="loading-header"></div>
                <table className="loading-table">
                    <thead>
                        <tr>
                            {
                                Array.from({length: cols}, (_, index) => (
                                        <td key={"0 " + index} className="loading-table-item-header"></td>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.from({length: rows}, (_, index) => (
                                <tr key={index}>
                                    {
                                        Array.from({length: cols}, (_, colIndex) => (
                                                <td key={index + " " + colIndex} className="loading-table-item"></td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="loading-placeholder">
            <LoadingTextItem lines={2} />
            <LoadingTextItem lines={5} />
            <LoadingTableItem rows={4} />
            {
                Array.from({length: 1}, (_, index) => (
                    <>
                        <LoadingTextItem key={index} lines={2} />
                        <LoadingTableItem rows={10} cols={15} />
                    </>
                ))
            }
        </div>
    );
};

function Report({ reportHTML, snippet = false, descriptionFolded = true, clarityMask = "True" }) {

    const [cleanedHTML, setCleanedHTML] = useState(undefined);

    useEffect(() => {
        // Load Plotly script
        const plotlyScript = document.createElement('script');
        plotlyScript.src = 'https://cdn.plot.ly/plotly-latest.min.js';
        plotlyScript.async = true;
        plotlyScript.id = 'plotly-script';
        document.body.appendChild(plotlyScript);

        // Cleanup script on component unmount
        return () => {
            document.body.removeChild(plotlyScript);
        };
    }, []);

    // Clean up the report HTML
    useEffect(() => {
        if (!reportHTML) return;
        const parser = new DOMParser();
        const doc = parser.parseFromString(reportHTML, 'text/html');
        const body = doc.body;

        // Remove the body styling generated in the newswitch report
        const styleTags = body.querySelectorAll('style');
        // Find the style tag that contains the 'title-page' class
        styleTags.forEach((styleTag) => {
            if (styleTag.textContent.includes('.title-page')) {
                const titlePageStyle = styleTag.textContent.replace(/body\s*{[^}]*}/, '');
                styleTag.textContent = titlePageStyle;
                return;
            }
        });

        // Make all news links open in a new tab
        const newsLinks = body.querySelectorAll('.section ul a');
        newsLinks.forEach(link => {
            if (!(link instanceof HTMLAnchorElement)) return;
            if (!link.href.startsWith("#") && !link.href.startsWith(window.location.href)) {
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
                // Replace the link text last word with a world emoji
                const text = link.textContent;
                const words = text.split(" ");
                words.pop();
                link.textContent = words.join(" ") + " 🌐";
            }
            // Append the classname of the most closely related parent ul element with 'news-item'
            const newsItem = link.closest('li');
            if (!newsItem) return;
            newsItem.classList.add('news-item');
        });

        // Add the class 'ppp' to all elements with id starting with 'PPP'
        const pppElements = body.querySelectorAll('[id^="PPP"]');
        pppElements.forEach(element => {
            element.classList.add('ppp');
        });

        // Add description classname
        const intro = body.querySelector('.intro');
        if (intro) {
            const descriptionSection = intro.nextElementSibling.querySelector('.section');
            if (descriptionSection) {
                descriptionSection.classList.add('description');
                if (descriptionFolded) {
                    descriptionSection.classList.add('folded');
                }
            }
        }

        setCleanedHTML(body.innerHTML);
    }, [reportHTML, descriptionFolded]);

    
    useEffect(() => {
        if (!cleanedHTML) return;
        // Execute Plotly scripts if present
        const plotlyScriptTags = document.querySelectorAll('script[type="text/javascript"]');
        plotlyScriptTags.forEach((scriptTag) => {
            // Create a new script element and set its content
            const newScriptTag = document.createElement('script');
            newScriptTag.type = 'text/javascript';
            newScriptTag.textContent = scriptTag.textContent;
            document.body.appendChild(newScriptTag);
            document.body.removeChild(newScriptTag);
        });

        // Add custom tooltip popup js logic
        document.querySelectorAll('.plotly-graph-div').forEach((plotElement) => {
            //if (!(plotElement instanceof Plotly)) return;
            plotElement.on('plotly_click', function(event) {
                var point = event.points[0];
                var url = point.customdata;
                if (url) {
                    window.open(url, '_blank', 'noreferrer, noopener');
                }
            });

            // Custom tooltip for legend hover
            const legendItems = plotElement.querySelectorAll('.legendtoggle');
            const tooltip = plotElement.parentNode.parentNode.querySelector('.custom-tooltip');

            legendItems.forEach(function(item) {
                item.addEventListener('mouseover', function(event) {
                    tooltip.style.display = 'block';
                    tooltip.innerHTML = plotElement.getAttribute('data-summary'); // Use data-summary attribute for tooltip content
                });
                item.addEventListener('mousemove', function(event) {
                    tooltip.style.left = (event.pageX + 10) + 'px';
                    tooltip.style.top = (event.pageY + 10) + 'px';
                });
                item.addEventListener('mouseout', function() {
                    tooltip.style.display = 'none';
                });
            });
        });
        
        // Rotate the x axis text 45 degrees for all plotly plots
        const xticks = document.querySelectorAll('.js-plotly-plot .xtick');
        xticks.forEach(xtick => {
            const prevTransform = xtick.firstChild.getAttribute('transform');
            if (prevTransform) {
                // Replace the first value of rotate(num1, num2, num3) by 45
                const newTransform = prevTransform.replace(/rotate\(([^,]+),/, 'rotate(25,');
                xtick.firstChild.setAttribute('transform', newTransform);
                return;
            }
        });


        // Add js to fold/unfold the description section
        const descriptionSection = document.querySelector('.description');
        if (descriptionSection && !descriptionSection.querySelector('.fold-button')) {
            const foldButton = document.createElement('button');
            foldButton.textContent = descriptionFolded ? 'Expand' : 'Hide';
            foldButton.classList.add('fold-button');
            foldButton.onclick = () => {
                descriptionSection.classList.toggle('folded');
                foldButton.textContent = descriptionSection.classList.contains('folded') ? 'Expand' : 'Hide';
            };
            // Add the button to the left of the description section
            const title = descriptionSection.querySelector('h2');
            if (title) {
                // Remove title
                title.remove();
                // Add a div element to hold the title and fold button
                const titleDiv = document.createElement('div');
                titleDiv.classList.add('description-title');
                titleDiv.appendChild(title);
                titleDiv.appendChild(foldButton);
                descriptionSection.insertBefore(titleDiv, descriptionSection.firstChild);
            }
        }

        if (snippet) {
            const universeTable = document.getElementById('client-universe').querySelector('table');
            if (universeTable) {
                const newRow = universeTable.insertRow();
                const newCell = newRow.insertCell();
                newCell.colSpan = 9;
                // make it quite tall and add paywall text
                newCell.innerHTML = '<div style="height: 100px; display: flex; justify-content: center; align-items: center;"><p style="font-size: 1em;"><a href="/dashboard">Sign in</a> or <a href="/dashboard">create a free account</a> to view the full report</p></div>';
            }
            const content = document.querySelector('.content');
            const textElement = document.createElement('div');
            textElement.innerHTML = '<div class="section sign-up-card"><p style="font-size: 1em;"><a href="/dashboard">Sign in</a> or <a href="/dashboard">create a free account</a> to view the full report</p></div>';
            content.appendChild(textElement);
        }

    }, [cleanedHTML, descriptionFolded, snippet]);

    useResetScroll();

    return (
        <div className={"report-container " + (snippet && "report-snippet")}>
            <script src="https://cdn.plot.ly/plotly-latest.min.js"></script>
            {
                cleanedHTML === undefined ? <Loading /> : <div dangerouslySetInnerHTML={{__html: cleanedHTML}} data-clarity-mask={clarityMask} />
            }
        </div>
    );
}

function DatabaseReport({ runID, descriptionFolded = true, clarityMask = "True" }) {
    const callAPI = useNewsWitchApi();

    const [report, setReport] = useState(undefined);
    const [news, setNews] = useState(undefined);

    // TODO: This currently gets all the runs and then filters based on the id - a new endpoint should be created to get a single run
    useEffect(() => {
        if (!runID) return;
        callAPI("GET", "/runs")?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const runs = Object.values(body["runs"])
                    // Find the run with the given ID
                    const run = runs.find(run => run["id"] === runID);
                    setReport(run);
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI, runID]);

    useEffect(() => {
        if (!runID) return;
        callAPI("GET", "/news?" + new URLSearchParams({
            run_id: runID,
        }).toString())?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const news = body["news"];
                    setNews(news);
                });
            } else {
                console.error(response);
                setNews({});
            }
        });
    }, [callAPI, runID]);

    useResetScroll();


    const intensities = ["Immediate", "Short-Term", "Medium-Term", "Long-Term"];
    return (
        <div className={"report-container"}>
            {
                report === undefined ? <Loading /> : 
                <div className="content" data-clarity-mask={clarityMask}>
                    <div className="header flex-row justify-between">
                        <p>Date: {report ? new Date(report["start_date"] * 1000).toDateString() : ""}</p>
                        <p>Generated at {report ? new Date(report["start_date"] * 1000).toTimeString().slice(0, 5) : ""}</p>
                    </div>
                    <div className="section intro">
                        <p><b className="text-xs">By viewing and/or using this report you are consenting to the Terms and Conditions of Zanista AI.</b></p>
                        <p>Content generated by GPT-4o</p>
                        <p>At Zanista AI, we provide a wide range of services including automated exam paper grading system (<Link to="/products/goodpoint">GoodPoint</Link>) and financial newsletter (<Link to="/products/newswitch">NewsWitch</Link>). Visit zanista.ai for more information.</p>
                        <p>Zanista AI Data Privacy and Confidentiality: NO input or output data is accessible to other users, OpenAI, or any third-party services. Data is NOT used to train or enhance any models. Verify information before use. Do not distribute without permission.</p>
                    </div>
                    <div id="client-universe" className="section">
                        <h2>Client Universe - Net Sentiment Score</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Ticker</th>
                                    <th>Name</th>
                                    <th>Number of important news</th>
                                    {
                                        intensities.map(intensity => (
                                            <th>Net Sentiment: {intensity}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            { news ?
                                Object.entries(news).sort(([t1, l1], [t2, l2]) => l2.length - l1.length).map(([ticker, newsList]) => {
                                    const importantNews = newsList.length - newsList.filter(item => !item["sentiment"]["direction"] || item["sentiment"]["direction"] === "Irrelevant").length;
                                    const sentimentMap = {};
                                    intensities.forEach(intensity => {
                                        const newsList = news[ticker].filter(item => item["sentiment"]["intensity"] === intensity);
                                        const positiveNews = newsList.filter(item => item["sentiment"]["direction"] === "Positive").length;
                                        const stronglyPositiveNews = newsList.filter(item => item["sentiment"]["direction"] === "Strongly Positive").length;
                                        const negativeNews = newsList.filter(item => item["sentiment"]["direction"] === "Negative").length;
                                        const stronglyNegativeNews = newsList.filter(item => item["sentiment"]["direction"] === "Strongly Negative").length;
                                        sentimentMap[intensity] = {
                                            "net_sentiment":  positiveNews + (3 * stronglyPositiveNews) - negativeNews - (3 *stronglyNegativeNews),
                                            "total_news": newsList.length * 3,
                                        };
                                    });

                                    return (
                                        <tr key={ticker}>
                                            <td><a href={`#${ticker}`}>{ticker}</a></td>
                                            <td>{ticker}</td>
                                            <td>{importantNews}</td>
                                            {
                                                intensities.map(intensity => (
                                                    <td style={{backgroundColor: sentimentMap[intensity]["net_sentiment"] > 0 ? "lightgreen" : sentimentMap[intensity]["total_news"] > 0 ? "lightcoral" : "transparent"}}>
                                                        {sentimentMap[intensity]["net_sentiment"]} / {sentimentMap[intensity]["total_news"]}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                )})
                                :
                                <tr>
                                    <td colSpan={9}>Loading...</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    { news ?
                        Object.entries(news).sort(([t1, l1], [t2, l2]) => l2.length - l1.length).map(([ticker, newsList]) => {
                            const dateLists = {};
                            newsList.forEach(item => {
                                if (!item["sentiment"]["direction"] || item["sentiment"]["direction"] === "Irrelevant") return;
                                const date = new Date(item["date"] * 1000).toDateString();
                                if (!dateLists[date]) {
                                    dateLists[date] = [];
                                }
                                dateLists[date].push(item);
                            });
                            return (
                                <>
                                <div id={ticker} className="section">
                                    <details>
                                        <summary>
                                            <h2>News summaries for {ticker}</h2>
                                        </summary>
                                        {
                                            Object.entries(dateLists).sort(([d1, v1], [d2, v2]) => ((+ (new Date(d1) < new Date(d2))) * 2) - 1).map(([date, newsList]) => (
                                                <details>
                                                    <summary>
                                                        Published Date: {date}
                                                    </summary>
                                                    <NewsList title="Positive News" newsList={newsList.filter(item => item["sentiment"]["direction"]?.includes("Positive"))} />
                                                    <NewsList title="Negative News" newsList={newsList.filter(item => item["sentiment"]["direction"]?.includes("Negative"))} />
                                                    <NewsList title="Neutral News (some irrelevant)" newsList={newsList.filter(item => item["sentiment"]["direction"] === "Neutral")} />
                                                </details>
                                            ))
                                        }
                                    </details>
                                </div>       
                                <div id={`PPP_${ticker}`} className="section">
                                    <h2>PPP for {ticker}</h2>
                                    <div>
                                        <PPPChart news={newsList}/>
                                    </div>
                                </div>
                                </>
                        )})
                        : 
                        <div className="section">
                            <h2>Loading...</h2>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

function NewsList({ title, newsList }) {
    if (newsList.length === 0) return null;
    return (
        <details>
            <summary>
                {title}
            </summary>
            <ul>
                {
                    newsList.map(item => {
                        return (
                            <li className="news-item">
                                <a href={item.url} target="_blank" rel="noopener noreferrer">Link to the news 🌐</a>
                                <br />
                                <br />
                                <div className="flex-row justify-between w-full">
                                    <span className="text-sm">Sentiment: {item["sentiment"]["direction"]} - {item["sentiment"]["intensity"]}</span>
                                    <span className="text-sm font-light">Source: {item["domain"]}</span>
                                </div>
                                <br />
                                <b className="text-sm">{item.title}</b>
                                <br />
                                <br />
                                {item["summary"] !== null && item["summary"] !== "" ? item["summary"] : item["snippet"]}
                                <br />
                            </li>
                        );
                    })
                }
            </ul>
        </details>
    )
}

const addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const addMonths = function(months) {
    var date = new Date(this.valueOf());
    date.setMonth(date.getMonth() + months);
    return date;
}

const addYears = function(years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years);
    return date;
}


const PPPChart = ({ news }) => {
    let mobileMode = false;
    if (window.innerWidth < 768) {
        mobileMode = true;
    }

    if (news.length === 0) return null;
    const ticker = news[0]["ticker"];

    const xData = [
        '-1 year', '-6 months', '-3 months', '-1 month', '-6 days', '-3 days', '-2 days', '-1 day', 'Event Date', 
        '+1 day', '+2 days', '+3 days', '+4 days', '+6 days', '+1 week', '+1 month', '+3 months', '+6 months', '+1 year'
    ];

    let eventDate = new Date();

    const addPeriod = function(date, value) {
        if (value.includes('year')) {
            return addYears.call(date, parseInt(value));
        } else if (value.includes('month')) {
            return addMonths.call(date, parseInt(value));
        } else if (value.includes('week')) {
            return addDays.call(date, parseInt(value) * 7);
        } else if (value.includes('day')) {
            return addDays.call(date, parseInt(value));
        } else {
            return date;
        }
    }

    const isWeekend = function(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    const isWithinPeriod = function(date, startDate, endDate) {
        return date >= startDate && date <= endDate;
    }

    const xDates = xData.map((date, index) => {
        return addPeriod(eventDate, date);
    });

    const xDataFormatted = xDates.map((date, index) => {
        const dateText = xData[index];
        const text = isWeekend(date) ? `* ${dateText}` : dateText;
        if (isWithinPeriod(date, addMonths.call(eventDate, -1), eventDate)) {
            return `<span style="color:red;">${text}</span>`
        } else {
            return text;
        }
    })

    const sentimentMap = {
        'Positive': 1,
        'Negative': -1,
        'Strongly Positive': 3,
        'Strongly Negative': -3,
        'Neutral': 0,
        '': 0
    }

    const intensityMap = {
        "Immediate": ["+1 day", "+2 days", "+3 days"],
        "Short-Term": ["+3 days", "+4 days", "+6 days"],
        "Medium-Term": ['+1 week', '+1 month'],
        "Long-Term": ['+3 months', '+6 months', '+1 year']
    }

    const xSentimentData = []
    const ySentimentData = []
    const sentimentText = []

    news.forEach(item => {
        const intensity = item["sentiment"]["intensity"];
        if (!intensityMap[intensity]) return;
        intensityMap[intensity].forEach(date => {
            xSentimentData.push(date);
            ySentimentData.push(sentimentMap[item["sentiment"]["direction"]]);
            sentimentText.push(`${item["title"]}`);
        });
    });

    const priceData = [-89.7, -64.8, -13.8, -14.5, -14.3, 5.8, -5.9, 0.0];
    const priceText = priceData.map(price => `${price.toFixed(1)}%`)

    for (let i = 0; i < 11; i++) {
        priceData.push(0.0);
        priceText.push(null);
    }

    const minPice = Math.min(...priceData);
    const maxPrice = Math.max(...priceData);
    const priceAbsolute = Math.max(Math.abs(minPice), Math.abs(maxPrice)) * 1.1;

    const maxSentiment = Math.max(...xData.map((date, index) => {
        const indexes = xSentimentData.reduce((acc, val, i) => (val === date ? acc.concat(i) : acc), []);
        return indexes.reduce((acc, val) => ySentimentData[val] >= 0 ? acc + ySentimentData[val] : acc, 0);
    }));

    const minSentiment = Math.min(...xData.map((date, index) => {
        const indexes = xSentimentData.reduce((acc, val, i) => (val === date ? acc.concat(i) : acc), []);
        return indexes.reduce((acc, val) => ySentimentData[val] <= 0 ? acc + ySentimentData[val] : acc, 0);
    }));
    
    const sentimentAbsolute = Math.max(Math.abs(minSentiment), Math.abs(maxSentiment)) * 1.025;

    return (
    <Plot className="w-full my-4"
        data={[
            {   // Price data
                x: xData,
                y: priceData,
                text: priceText,
                type: 'scatter',
                mode: 'text+lines+markers',
                line: { 
                    color: 'rgba(0, 0, 255, 0.6)',
                    width: 3,
                },
                hoverinfo: 'text',
                textposition: 'top center',
                textfont: {
                    color: 'black',
                    size: 12,
                },
                name: `${ticker}<br>Type: ${"nan"}, Class: ${"Equity"}<br>Event Date: ${eventDate.toISOString().slice(0, 10)}`,
                showlegend: true,
                yaxis: 'y1',
            },
            {   // Sentiment data
                x: xSentimentData,
                y: ySentimentData,
                text: sentimentText,
                textfont: {
                    // invisible
                    color: 'rgba(0, 0, 0, 0)',
                },
                type: 'bar',
                yaxis: 'y2',
                name: 'News Sentiment',
                marker: { 
                    color: ySentimentData.map((val, index) => val >= 0 ? 'rgba(0, 204, 102, 0.5)' : 'rgba(255, 0, 0, 0.5)'),
                    line: {
                        width: 1.4,
                        color: 'rgba(255, 255, 255, 1)',
                    }
                },
                showlegend: false,
                hoverinfo: 'text',
                offset: -0.95,
            },
        ]}
        layout={{
        margin: { 
            t: 10,
            b: 80, 
            l: mobileMode ? 20 : 80, 
            r: mobileMode ? 20 : 80
        },
        barmode: 'relative',
        bargap: 0.1,
        hovermode: 'closest',
        xaxis: {
            tickvals: xData,
            ticktext: xDataFormatted,
            ticks: 'outside',
            tickfont: {
                size: 16,
            },
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
            zeroline: false,
        },
        yaxis: {
            title: {
                text: `${ticker} (source: yf)`,
                font: {
                    size: 18,
                }
            },
            side: 'left',
            range: [-priceAbsolute, priceAbsolute],
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
        },
        yaxis2: {
            title: {
                text: 'News Sentiment',
                font: {
                    size: 18,
                }
            },
            overlaying: 'y',
            side: 'right',
            range: [-sentimentAbsolute, sentimentAbsolute],
            showgrid: false,
        },
        shapes: [
            {
                type: 'line',
                x0: 'Event Date',
                x1: 'Event Date',
                yref: 'paper',
                y0: 0,
                y1: 1,
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dash',
                },
            },
            {   // Horizon line
                type: 'line',
                x0: 0,
                x1: 1,
                xref: 'paper',
                y0: 0,
                y1: 0,
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dash',
                },
            },
        ],
        showlegend: true,
        legend: {
            x: 0.02,
            y: 0.98,
            xanchor: 'left',
            yanchor: 'top',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            font: {
                size: 18,
            },
            itemclick: false,
        }
        }}
        config={{ 
            responsive: true,
            scrollZoom: false,
            displayModeBar: false,
        }}
    />
    );
};

export {Report, DatabaseReport};
