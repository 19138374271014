import { useState, useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";

export default function useAccessToken(msalRequest) {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const request = {
            ...msalRequest,
            account: instance.getActiveAccount(),
        }

        if (isAuthenticated && inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(request)
                .then(response => {
                    setAccessToken(response.accessToken);
                })
                .catch(async (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        instance
                            .acquireTokenRedirect(request)
                            .then((response) => {
                                setAccessToken(response.accessToken);
                            })
                            .catch(e => {
                                console.error(e);
                            });
                    } else {
                        console.error(error);
                    }
                });
        }
    }, [inProgress, isAuthenticated, accounts, instance, msalRequest]);

    return accessToken
}