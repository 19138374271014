import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";


export function getAssessmentObjects() {
    return getFromLocalStorage(StorageKeys.ASSESSMENT_OBJECTS, {});
}

export function setAssessmentObjects(assessmentObjects) {
    return saveToLocalStorage(StorageKeys.ASSESSMENT_OBJECTS, assessmentObjects);
}

export function getAssessmentObjectById(assessmentId) {
    return getAssessmentObjects()[assessmentId];
}

export function saveAssessmentObject(assessment) {
    const newAssessmentObjects = getAssessmentObjects();
    newAssessmentObjects[assessment.id] = assessment;
    return setAssessmentObjects(newAssessmentObjects);
}