export const PrivacyPolicy = `

# Zanista AI LTD Privacy Policy

This policy is effective as of 7 June 2024

# Introduction

**Who we are**. When we refer to “us”, “we”, “our”, or “Zanista AI” in this Statement, we mean Zanista AI LTD.

Your privacy is important to us at Zanista AI. Our policy is to respect your privacy regarding any information we may collect or use from you. This privacy statement applies to the personal information we collect about you through our website, [https://zanista.ai](https://zanista.ai), and other sites we own and operate.

We collect and use personal information to allow us to provide our products and services to our clients and end users. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.

Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.

You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.

## Changes to this Privacy Policy

We keep our Privacy Policy under regular review. We reserve the right to modify or supplement this Privacy Policy at any time. If we make a change to this Privacy Policy, we will post such changes on our website. The “last updated” date at the top of
this privacy notice reflects when the most recent changes were made. We
encourage you to periodically review this privacy notice for any amendments. You
can find the latest version at [https://zanista.ai/legal?tab=privacy](https://zanista.ai/legal?tab=privacy).

# What information we collect and use

To provide and improve our services, operate user accounts and for marketing purposes we collect the following information:

- Names and contact details (including email addresses)

- Purchase or account history

- Payment details (including card or bank information for transfers and direct debits)

- Addresses

- Username and password

- Account information (such as job title and student ID number)

- Imported profile information from third-party websites if you choose to register and/or log in with credentials from these websites

- Website user information (including user journeys and cookie tracking)

- Analytic information (such as time spent using our products and how you use our products)

- Submitted content (such as uploaded exam papers)

- Information relating to compliments or complaints

- Any other information you submit during communication with us (for example through a support request)

- Marketing preferences

## Children and Personal Data

At Zanista AI we understand the importance of protecting the personal data of children under the age of 16. It is not our intention to collect personal data from a child. If you believe that a child has disclosed personal data or that we hold personal information about a child, please email us at [info@zanista.ai](mailto:info@zanista.ai).

# Where we get this information from

We get the information mentioned above:

- **Directly from you** when we ask you for information during creation of your account and use of our products and services

- **Indirectly from you** when we collect information about the way you use our products and services.

- From **third-party websites** if you choose to register and/or log in with credentials from these websites.

# How and why we use this information

We use the information mentioned above to provide our products and services as well as provide support. We require this information for:

- Creating and maintaining user accounts

- Operating, maintaining and improving our
  products and services (including through the use of analytics)

- Request for information or materials

- Assigning support tickets

- Providing support for our products and services

- Personalising the experience of our products and
  services

Our legal bases for controlling or possessing your personal
data are:

- **Consent**: You provide informed consent to us or have a reasonable expectation that we will use your information in a certain way – for example, to hear about new services or offers. You can withdraw your consent at any time either by request to [info@zanista.ai](mailto:info@zanista.ai)

- **Contract**: Providing our services and fulfilling our obligations to you, usually relating to a terms of service or partnership agreement

- **Legal Obligation**: The necessity to meet compliance with our legal obligations

- **Legitimate Interest**: Where it is in our legitimate interests to do so. We only rely on 'legitimate interests' as the legal basis for processing by us, or third parties we use, for providing login systems to users via their existing social media accounts.

# Who we share information with

We restrict access to Personal Information to Zanista AI employees, contractors and agents who have a need to know that information in order to process it on our behalf. These individuals are bound by confidentiality obligations and may be subject to discipline, up to and including termination, as well as civil action and criminal prosecution, if they fail to meet these obligations.

Where we use third-party tools (for example to perform payment processing), these third parties will have access to some of your personal information.

As may be necessary in the course of providing our product offerings to you, we may disclose information to the payment processors.

## Information security

What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.

Despite all the security measures that we take, we do not guarantee that we will eliminate all risk of misuse of, or unauthorized access to, your Personal Information. It is not possible to achieve perfect security, and we cannot guarantee or warrant the security of any data (including Personal Information) that we receive and store on our or our service providers’ systems.

You are responsible for keeping any passwords secure to prevent unauthorized access to and use of any Zanista AI products or services to which you have access.

# How long we hold this information for

We will retain your Personal Information for so long as you maintain an active account or user profile, or as needed to provide you with your requested product or service.

If you wish to remove or restrict the collection of data with an account you have with us, you may do so by contacting us at [info@zanista.ai](mailto:info@zanista.ai). We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce this Privacy Policy and any applicable user agreements. We will take steps to ensure that your Personal Information is destroyed, erased or made anonymous as soon as the purpose for which it was collected is no longer relevant, or as required by law.

Please be aware that, as long as you have access to Zanista AI products and services you cannot opt out of receiving service messages from us.

# GDPR

The GDPR protects European Union data subjects’ fundamental right to privacy and the protection of personal data.

Zanista AI complies with the GDPR and you have the right to lodge a complaint with a supervisory authority in relation to the processing of your personal data if it is not in compliance with the GDPR.

# CCPA

If you are a California resident, the California Consumer Privacy Act (CCPA) gives you rights in relation to your Personal Information.

# Contact information

If you have any comments, questions or concerns about this Privacy Policy, the data that we collect from you or how that data is being used, or to report suspected misuse of your information, please contact us at [info@zanista.ai](mailto:info@zanista.ai) or write to us at:

Zanista AI LTD

Flat 116 Falconbrook Gardens 17 Silvertown Way

London

England

E16 1PG

`
