import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import DownloadButton from "src/components/ui/DownloadButton";


export default function DownloadOverallFeedback({ assessmentId, ...props }) {
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Grading.Feedback.Overall.Download(assessmentId)}
            filename={`${assessmentId}_feedback_overall.pdf`}
            className="float-right"
            text={`Download Overall Feedback`}
            {...props}
        />
    )
}