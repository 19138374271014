import { Collapse, Expand } from "iconoir-react";
import { useContext } from "react";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import IconButton from "src/components/ui/IconButton";

export default function ExpandCollapseButtons({ props={}, expandProps={}, collapseProps={} }) {
    const {isExpanded, expand, isCollapsed, collapse} = useContext(ExpandCollapseContext);

    var expandButtonProps = {
        Icon: Expand,
        onClick: expand,
        text: "Expand",
        gapSize: null,
        paddingSize: "var(--padding-small)",
        bgColour: `${isExpanded ? "var(--orange-mid)" : "var(--yellow-light)"}`
    }
    expandButtonProps = { ...expandButtonProps, ...expandProps, ...props }

    var collapseButtonProps = {
        Icon: Collapse,
        onClick: collapse,
        text: "Collapse",
        gapSize: null,
        paddingSize: "var(--padding-small)",
        bgColour: `${isCollapsed ? "var(--orange-mid)" : "var(--yellow-light)"}`
    }
    collapseButtonProps = { ...collapseButtonProps, ...collapseProps, ...props }

    return <div className="expand-collapse-buttons flex-row gap-small">
        <IconButton {...expandButtonProps} />
        <IconButton {...collapseButtonProps} />
    </div>
}