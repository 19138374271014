import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

export function getAllStudentAnswers(assessmentId) {
    return getFromLocalStorage(StorageKeys.STUDENT_ANSWERS, {})[assessmentId];
}

export function setAllStudentAnswers(assessmentId, allStudentAnswers) {
    const store = getFromLocalStorage(StorageKeys.STUDENT_ANSWERS, {});
    store[assessmentId] = allStudentAnswers;
    return saveToLocalStorage(StorageKeys.STUDENT_ANSWERS, store);
}

export function getStudentAnswerById(assessmentId, studentId) {
    const classAnswers = getAllStudentAnswers(assessmentId) ?? {};
    return classAnswers[studentId];
}

export function saveStudentAnswerById(assessmentId, studentId, studentAnswer) {
    const allStudentAnswers = getAllStudentAnswers(assessmentId) ?? {};
    allStudentAnswers[studentId] = studentAnswer;
    setAllStudentAnswers(assessmentId, allStudentAnswers);
}

export function getStudentList(assessmentId) {
    return Object.keys(getAllStudentAnswers(assessmentId));
}

export function updateStudentsList(assessmentId, studentList) {
    const allStudentAnswers = getAllStudentAnswers(assessmentId) ?? {};
    for (const studentId in allStudentAnswers) {
        if (studentList[studentId] === undefined) {
            delete allStudentAnswers[studentId];
        }
    }
    for (const studentId in studentList) {
        if (allStudentAnswers[studentId] === undefined) {
            allStudentAnswers[studentId] = {};
        }
    }
    setAllStudentAnswers(assessmentId, allStudentAnswers);
    return studentList;
}