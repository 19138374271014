import React from "react";
import { Outlet } from "react-router-dom";
import ZanistaHeader from "./components/nav/ZanistaHeader";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "src/api/CustomNavigationClient";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "src/auth/AuthConfig";
import SignInLoading from "src/pages/login/SignInLoading";

// TODO: support more locales
import 'dayjs/locale/en'
import 'dayjs/locale/en-gb'
import { clarity } from "react-microsoft-clarity";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // initialize the error state
        this.state = { hasError: false };
    }

    // if an error happened, set the state to true
    static getDerivedStateFromError(error) {
        console.log(error);
        return { hasError: true };
    }

    render() {
        // if error happened, return a fallback component
        if (this.state.hasError) {
        return <div></div>
        }

        return this.props.children;
    }
}

function getLang() {
    if (navigator.languages !== undefined) 
        return navigator.languages[0].toLowerCase(); 
    return 'en-gb';
}

export default function App({ msal }) {

    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    msal.setNavigationClient(navigationClient);

    const authRequest = {
        ...loginRequest,
    };

    // User has consented to cookies by using GoodPoint
    if (clarity.hasStarted()) {
        clarity.consent();
    }

    return (
        <MsalProvider instance={msal}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLang()}>
                <ErrorBoundary>
                    <MsalAuthenticationTemplate 
                        interactionType={InteractionType.Redirect} 
                        authenticationRequest={authRequest}
                    >
                        <div className="App">
                            <ZanistaHeader />
                            <Outlet />
                        </div>
                    </MsalAuthenticationTemplate>
                    <UnauthenticatedTemplate>
                        <SignInLoading />
                    </UnauthenticatedTemplate>
                </ErrorBoundary>
            </LocalizationProvider>
        </MsalProvider>
    );
}
