import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

export function getRubrics() {
    return getFromLocalStorage(StorageKeys.RUBRICS, {});
}

export function setRubrics(rubrics) {
    return saveToLocalStorage(StorageKeys.RUBRICS, rubrics);
}

export function getRubricById(assessmentId, enhanced=false) {
    const rubric = getRubrics()[assessmentId];
    return rubric && rubric[enhanced ? "enhanced" : "original"];
}

export function saveRubric(assessmentId, rubric, enhanced=false) {
    const newRubrics = getRubrics();
    if (newRubrics[assessmentId]) {
        newRubrics[assessmentId][enhanced ? "enhanced" : "original"] = rubric;
    } else {
        newRubrics[assessmentId] = {
            [enhanced ? "enhanced" : "original"]: rubric
        };
    }
    return setRubrics(newRubrics);
}