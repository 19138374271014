import { useContext, useState, useCallback } from "react";
import { AssessmentsListContext } from "src/pages/goodpoint/dashboard/AssessmentsListContext";
import useCallApi from "src/api/useCallApi";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { deleteLocalAssessment } from "src/storage/AssessmentDesc";
import { protectedResources } from "src/auth/AuthConfig";

export default function useDeleteAssessment() {
    const { setAssessments } = useContext(AssessmentsListContext);
    const callApi = useCallApi({
        msalRequest: {scopes: protectedResources.apiGoodPoint.scopes.write}
    });

    const [deleting, setDeleting] = useState(false);

    const deleteAssessment = useCallback((id, afterDelete) => {
        setDeleting(true);
        callApi("POST", GoodPointApi.Assessment.Delete(id))
            ?.then(response => {
                if (response.status === 200 || response.status === 400) {
                    console.log(`Deleted assessment ${id}`);
                    setAssessments((assessments) => {
                        deleteLocalAssessment(id);
                        const result = { ...assessments };
                        delete result[id];
                        return result;
                    });
                } else {
                    console.error(`Failed to delete assessment: ${response.status} ${response.statusText}`);
                }
            }).finally(() => {
                setDeleting(false);
                afterDelete && afterDelete();
            });
    }, [callApi, setAssessments]);

    return {
        deleteAssessment,
        deleting
    };
}