import { TailSpin } from "react-loader-spinner";

import "./Loading.css";

export default function Loading({ size=20, color="black" }) {
    return (
        <div className="flex-row align-center font-x-large">
            <TailSpin width={size} height={0.8 * size} color={color} strokeWidth={3.0} wrapperClass="padding-small"/>
            <p style={{color, fontSize: size}}>Loading</p>
            <p className="loading-ellipsis" style={{color, fontSize: size}}> </p>
        </div>
    )
}