import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useCallApi from 'src/api/useCallApi';
import { protectedResources } from "src/auth/AuthConfig";

export const CheckoutReturn = () => {
    const callApi = useCallApi({
        msalRequest: {scopes: protectedResources.apiGoodPoint.scopes.write}, // This should not be needed
    });
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState("");
    console.log(customerEmail);
  
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get("session_id");
        callApi("GET", `${protectedResources.apiPayments.endpoint}/session-status?session_id=${sessionId}`)?.then(
            (response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        setStatus(body.status);
                        setCustomerEmail(body.customer_email);
                    });
                } else {
                    console.error(
                        "Failed to get session status: " +
                        response.status +
                        " " +
                        response.statusText
                    );
                }
            }
        );
    }, [callApi]);
  
    if (status === "open") {
      return <Navigate to="/checkout" />;
    }
  
    if (status === "complete") {
        return <Navigate to="/dashboard" />;
        // return (
        //     <section id="success" className="container mx-auto py-6">
        //         <p className="text-center">
        //             We appreciate your business! A confirmation email will be sent to{" "}
        //             {customerEmail}. If you have any questions, please email{" "}
        //             <a href="mailto:info@zanista.ai">info@zanista.ai</a>.
        //         </p>
        //     </section>
        // );
    }
  
    return null;
  };

  