import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import { getRubricById, saveRubric } from "src/storage/RubricStorage";

export default function useFetchRubric(id, type) {
    const cacheGet = useCallback(
        () => getRubricById(id, type),
        [id, type]
    );
    const cacheSave = useCallback((rubric) => saveRubric(id, rubric, type), [id, type]);
    return useMemoFetch({
        apiEndpoint: GoodPointApi.Rubric.All(id, type),
        processResponse: (response) => response.json().then(body => body["rubric"]),
        cacheGet, cacheSave
    })
}