import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { StudentsMenu } from "src/pages/goodpoint/assessment/components/StudentsMenu";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";

import "./AnswersContent.css";
import useFetchStudentAnswers from "src/api/goodpoint/useFetchStudentAnswers";
import DownloadButton from "src/components/ui/DownloadButton";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import Loading from "src/components/ui/Loading";
import AnswersStructure from "src/pages/goodpoint/assessment/tabs/answers/structure/AnswersStructure";
import Divider from "src/components/layout/Divider";


function DownloadStudentAnswers({ assessmentId, studentId, ...props }) {
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.StudentAnswers.Download(assessmentId, "ocr", studentId)}
            filename={`${assessmentId}_student_answers_${studentId}.pdf`}
            className="download-student-answers float-right"
            text={`Download Answers`}
            {...props}
        />
    )
}

function StudentAnswers() {
    const {assessmentObject} = useContext(AssessmentContext);
    const {selectedStudent} = useContext(StudentsContext);

    const assessmentId = assessmentObject.id;
    const studentId = selectedStudent?.student_id;

    if (!selectedStudent) {
        return <Loading/>;
    }

    var answers = Object
        .values(selectedStudent["student_answers"])
        .sort((answer1, answer2) => {
            return answer1.question_number - answer2.question_number
        }); 

    return <div className="student-answers">
        <div className="answers-container">
            {
                answers != null 
                    ? <AnswersStructure selectedStudent={selectedStudent} structureMap={answers} /> 
                    : <Loading/>
            }
        </div>
        {
            selectedStudent && <DownloadStudentAnswers assessmentId={assessmentId} studentId={studentId} />
        }
    </div>
}

export default function AnswersContent() {
    const {assessmentObject} = useContext(AssessmentContext);
    const fetchStudentAnswers = useFetchStudentAnswers(assessmentObject.id);

    const [selectedStudent, setSelectedStudent] = useState(null);
    
    const students = useMemo(() => {
        const answers = fetchStudentAnswers();
        return answers;
    }, [fetchStudentAnswers]);

    useLayoutEffect(() => {
        if (!students || selectedStudent) return;
        setSelectedStudent(students[Object.keys(students)[0]]);
    }, [students, selectedStudent]);

    return (
        <StudentsContext.Provider value={{ students, selectedStudent, setSelectedStudent }}>
            <div className="answers-content flex-col gap-mid">
                <StudentsMenu />
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.3)'/>
                <StudentAnswers />
            </div>
        </StudentsContext.Provider>
    )
}