import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

export function getGrading(assessmentId) {
    return getFromLocalStorage(StorageKeys.GRADING_RESULTS, {})[assessmentId];
}

export function setGrading(assessmentId, allGrading) {
    const store = getFromLocalStorage(StorageKeys.GRADING_RESULTS, {});
    store[assessmentId] = allGrading;
    return saveToLocalStorage(StorageKeys.GRADING_RESULTS, store);
}

export function getGradingGeneralFeedback(assessmentId) {
    const grading = getGrading(assessmentId) ?? {};
    return grading["general_feedback"];
}

export function saveGradingGeneralFeedback(assessmentId, generalFeedback) {
    const grading = getGrading(assessmentId) ?? {};
    grading["general_feedback"] = generalFeedback;
    return setGrading(assessmentId, grading);
}

export function getAllGradingFeedback(assessmentId) {
    const store = getGrading(assessmentId) ?? {};
    return store["student_feedback"];
}

export function setAllGradingFeedback(assessmentId, studentFeedback) {
    const store = getGrading(assessmentId) ?? {};
    store["student_feedback"] = studentFeedback;
    return setGrading(assessmentId, store);
}

export function getGradingFeedbackById(assessmentId, studentId) {
    const classFeedback = getAllGradingFeedback(assessmentId) ?? {};
    return classFeedback[studentId];
}

export function saveGradingFeedbackbyId(assessmentId, studentId, feedback) {
    const studentGrading = getAllGradingFeedback(assessmentId) ?? {};
    studentGrading[studentId] = feedback;
    return setAllGradingFeedback(assessmentId, studentGrading);
}

export function getGradingQuestionsFeedbackById(assessmentId, studentId) {
    const studentFeedback = getGradingFeedbackById(assessmentId, studentId) ?? {};
    return studentFeedback["per_question"];
}

export function saveGradingQuestionsFeedbackById(assessmentId, studentId, questionFeedback) {
    const studentFeedback = getGradingFeedbackById(assessmentId, studentId) ?? {};
    studentFeedback["per_question"] = questionFeedback;
    return saveGradingFeedbackbyId(assessmentId, studentId, studentFeedback);
}

export function getGradingOverallFeedbackbyId(assessmentId, studentId) {
    const studentFeedback = getGradingFeedbackById(assessmentId, studentId) ?? {};
    return studentFeedback["overall_feedback"];
}

export function saveGradingOverallFeedbackById(assessmentId, studentId, overallFeedback) {
    const studentFeedback = getGradingFeedbackById(assessmentId, studentId) ?? {};
    studentFeedback["overall_feedback"] = overallFeedback;
    return saveGradingFeedbackbyId(assessmentId, studentId, studentFeedback);
}


export function getGradingGeneralResults(assessmentId) {
    const grading = getGrading(assessmentId) ?? {};
    return grading["general_results"];
}

export function saveGradingGeneralResults(assessmentId, results) {
    const grading = getGrading(assessmentId) ?? {};
    grading["general_results"] = results;
    return setGrading(assessmentId, grading);
}

export function getGradingAllStudentsResults(assessmentId) {
    const grading = getAllGradingFeedback(assessmentId);
    if (!grading) return undefined;
    return Object.fromEntries(
        Object
            .entries(grading)
            .map(([studentId, data]) => {
                return [studentId, {
                    "student_id": studentId,
                    "grade": data["grade"],
                    "total_marks": data["total_marks"]
                }];
            })
    );
}

export function saveGradingAllStudentsResults(assessmentId, allStudentsResults) {
    const grading = (getAllGradingFeedback(assessmentId) ?? {})["student_feedback"] ?? {};
    return setAllGradingFeedback(assessmentId, 
        Object.fromEntries(
            Object
                .entries(allStudentsResults)
                .map(([studentId, data]) => {
                    const f = grading[studentId] ?? {};
                    const new_data = {...f, ...data};
                    return [studentId, new_data];
                })
        )
    );
}