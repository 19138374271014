import { useCallback } from "react";

export default function useOptions(accessToken) {
    return useCallback((method, headers=[], body=null) => {
        const actualHeaders = new Headers();
        const bearer = `Bearer ${accessToken}`;
        actualHeaders.append("Authorization", bearer);
        if (!(body instanceof FormData)) {
            // Set content-type to json unless formdata
            actualHeaders.set("Content-Type", "application/json");
        } 
        // Override any headers with headers passed to function
        if (Array.isArray(headers) && headers.length > 0) {
            headers.forEach(header => {
                for (const [key, value] of Object.entries(header)) {
                    actualHeaders.set(key, value);
                    }
            });
        } else if (!Array.isArray(headers)) { 
            console.error("Headers must be an array of objects with name and value properties");
        }
    
        let options = {
            method: method,
            headers: actualHeaders,
            body: body ? 
                ((body instanceof FormData) ? body : JSON.stringify(body)) 
                : null,
        };
        return options
    }, [accessToken]);
}