import { Collapse, Expand } from "iconoir-react";
import { useContext, useMemo } from "react";
import { useCollapse } from "react-collapsed";
import Latex from "react-latex-next";
import { useFetchStudentFeedback } from "src/api/goodpoint/useFetchFeedback";
import IconButton from "src/components/ui/IconButton";
import Loading from "src/components/ui/Loading";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { AiGenerated } from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";


export default function StudentOverallFeedback() {
    const {assessmentObject} = useContext(AssessmentContext);
    const {selectedStudent} = useContext(StudentsContext);

    const fetchOverallFeedback = useFetchStudentFeedback(assessmentObject.id, selectedStudent?.student_id);

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        collapsedHeight: 120,
        hasDisabledAnimation: true
    });

    const overallFeedback = useMemo(() => {
        if (!selectedStudent) return undefined;
        return fetchOverallFeedback();
    }, [selectedStudent, fetchOverallFeedback]);

    return (
        <div className="student-feedback">
            <div className="feedback-header flex-row justify-content-space-between align-center">
                <div className="flex-row section-heading-left gap-small">
                    <b>Student Feedback</b>
                    <AiGenerated />
                </div>
                <div className="section-heading-controls">
                    <IconButton
                        Icon={isExpanded ? Collapse : Expand}
                        text={isExpanded ? "Collapse" : "Expand"}
                        bgColour={isExpanded ? "var(--orange-mid)" : "var(--yellow-light)"}
                        paddingSize="2px 5px"
                        borderSize="var(--border-thin)"
                        {...getToggleProps()}
                    />
                </div>
            </div>
            {
                overallFeedback
                    ? <div {...getCollapseProps()}>
                        <div className={`${isExpanded ? "" : "fade-paragraph"}`}>
                            { overallFeedback && <Latex>{overallFeedback}</Latex> }
                        </div>
                    </div>
                    : <Loading />
            }
        </div>
    )
};