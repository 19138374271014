import { CheckSquareSolid, Square, FastArrowUp, FastArrowDown } from "iconoir-react";
import { useContext, useState, useMemo } from "react";
import { useFetchOverallQuestionFeedback } from "src/api/goodpoint/useFetchFeedback";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import QuestionFeedbackStructure from "src/pages/goodpoint/assessment/tabs/grading/class-performance/question-feedback/QuestionFeedbackStructure";


export default function QuestionFeedback() {
    const {assessmentObject} = useContext(AssessmentContext);
    
    const fetchFeedback = useFetchOverallQuestionFeedback(assessmentObject.id);

    const [isFilterOnlyMistakes, setIsFilterOnlyMistakes] = useState(false);
    const [orderBy, setOrderBy] = useState("question_number");
    const [isAscendingOrder, setIsAscendingOrder] = useState(true);

    const questionFeedback = useMemo(() => {
        const result = fetchFeedback();
        return result;
    }, [fetchFeedback]);

    const sortedQuestionFeedback = useMemo(() => {
        if (!questionFeedback) return null;
        return Object
            .values(questionFeedback)
            .sort((feedback1, feedback2) => {
                var result = (orderBy === "percentage")
                    ? (feedback1["avg_mark"] / feedback1["max_marks"]) - (feedback2["avg_mark"] / feedback2["max_marks"])
                    : feedback1[orderBy] - feedback2[orderBy];
                return isAscendingOrder ? result : -result;
            });
    }, [isAscendingOrder, orderBy, questionFeedback]);

    const hasMistakes = useMemo(() => {
        if (!questionFeedback) return;
        return Object.values(questionFeedback).some((feedback) => feedback["avg_mark"] < feedback["max_marks"]);
    }, [questionFeedback])
    

    return <div className="feedback-container">
        <div className="feedback-options">
            <div className={`feedback-mistakes-filter flex-row align-center margin-small ${hasMistakes ? "" : "translucent"}`}>
                <div 
                    className={`${hasMistakes ? "cursor-pointer" : "pointer-disabled"}`}
                    onClick={() => hasMistakes && setIsFilterOnlyMistakes(!isFilterOnlyMistakes)}
                >
                    {
                        isFilterOnlyMistakes ? <CheckSquareSolid color="var(--orange-dark)" /> : <Square color="var(--orange-dark)" />
                    }
                </div>
                <p className="margin-small">Show only mistakes</p>
            </div>
            <div className="feedback-order-by flex-row align-center margin-small">
                <p>Order by:</p>
                <select className="order-by-select padding-x-small font-size-large margin-small" value={orderBy} onChange={(event) => setOrderBy(event.target.value)}>
                    <option value="question_number">Question Number</option>
                    <option value="avg_mark">Marks</option>
                    <option value="percentage">Percentage</option>
                </select>
                <div className="cursor-pointer" onClick={() => setIsAscendingOrder(!isAscendingOrder)}>
                    {
                        isAscendingOrder ? <FastArrowUp color="var(--orange-dark)" /> : <FastArrowDown color="var(--orange-dark)" />
                    }
                </div>
            </div>
        </div>
        <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
        <QuestionFeedbackStructure 
            questionFeedback={sortedQuestionFeedback}
            isFilterOnlyMistakes={isFilterOnlyMistakes}
        />
    </div>
}