import './Home.css'
import zanistaImg from 'src/assets/logo.png';
import msFoundersImg from 'src/assets/home/ms-founders.png';
import azureImg from 'src/assets/home/azure.png';
import microsoftImg from 'src/assets/home/microsoft.png';
import heroImg from 'src/assets/home/goodpoint-hero.png';
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import HomeHeader from 'src/pages/home/components/HomeHeader';
import HomeFooter from 'src/pages/home/components/HomeFooter';
import { DashboardSpeed, Group, TestTube, Timer, Atom, ShieldCheck } from 'iconoir-react';
import Feature from 'src/pages/home/components/Feature';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import useResetScroll from 'src/hooks/useResetScroll';

function Home({ msal }) {

    // Add/Remove class to body to change padding on home page only
    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add('landing-page-active');
        document.getElementById("zanista-header").classList.add('landing-page-active');
        // Remove the class when the component unmounts
        return () => {
            document.body.classList.remove('landing-page-active');
            try {
                document.getElementById("zanista-header").classList.remove('landing-page-active');
            } catch (error) {
                console.log("Could not find element by id")
            }
        };
    }, []);

    useResetScroll();

    return (
        <MsalProvider instance={msal}>
            <HomeHeader />
            <HeroSection />
            <FeaturesSection />
            <TestimonialsSection />
            <FAQSection />
            <TryNowSection />
            <div className='mx-8'>
                <HomeFooter />
            </div>
        </MsalProvider>
    );
}

function HeroSection() {
    return (
        <div className="hero-section px-4">
            <div className="container mx-auto h-full">
                <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-[100px] h-full">
                    <div className="flex-col h-full justify-start md:mt-[200px] mt-0">
                        <HeadlineSection />
                        <CTASection />
                    </div>
                    <div className="lg:flex-col h-full justify-start md:mt-[200px] mt-0 ml-4 overflow-visible hidden lg:flex">
                        <div className='w-[1200px] h-[500px] bg-transparent rounded'>
                            <img className="object-contain h-full rounded shadow-lg" src={heroImg} alt="GoodPoint" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function HeadlineSection() {
    return (
        <div className="flex-col justify-content-center items-start py-6">
            <h1 className="py-5 text-start md:text-5xl text-4xl kanit-medium">
                Effortless, accurate, and personalized feedback for students
            </h1>
            <p className="text-start">
                Streamline exam grading using AI with GoodPoint
            </p>
            <p className="text-start">
                Made for educators and students
            </p>
        </div>
    )
}

function CTASection() {
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO}>
                <button className="clickable text-sm md:text-lg bg-orange-300 hover:bg-orange-300 text-black outline outline-2 rounded shadow-xl">
                    Request a demo
                </button>
            </ContactPopup>
            <Link to="products/goodpoint">
                <button className="clickable text-sm md:text-lg bg-transparent hover:bg-orange-300 text-black hover:text-black outline outline-2 rounded shadow-xl">
                    Learn more
                </button>
            </Link>
        </div>
    )
}

function FeaturesSection() {
    return (
        <div className="bg-transparent pb-12 px-8">
            <div className="container mx-auto">
                <div className="grid md:grid-cols-2 px-0 md:px-8 grid-cols-1 gap-x-4 gap-y-8 md:gap-y-16">
                <Feature 
                    icon={<Group size={32}/>} 
                    title={"Personalised feedback for every student"} 
                    description={"Question by question feedback gives students the necessary resources to learn from their mistakes."}
                />
                <Feature 
                    icon={<Timer size={32}/>} 
                    title={"More time for educators"} 
                    description={"GoodPoint allows educators to focus more on teaching and less on marking."}
                />
                <Feature 
                    icon={<TestTube size={32}/>} 
                    title={"Pilot programs and partnerships"} 
                    description={"We are currently engaged in pilot programs with educators from all over the world to improve our product."}
                />
                <Feature 
                    icon={<DashboardSpeed size={32}/>} 
                    title={"Fast marking with AI"} 
                    description={"No more waiting weeks for results, only to have forgotten the content."}
                />
                <Feature 
                    icon={<Atom size={32}/>} 
                    title={"Specialization in STEM Subjects"} 
                    description={"Our focus is on STEM subjects for pre-university, undergraduate, and postgraduate courses."}
                />
                <Feature 
                    icon={<ShieldCheck size={32}/>} 
                    title={"Commitment to Data Privacy"} 
                    description={"We ensure GDPR-compliant data protection, emphasizing strict security measures without saving or sharing user data with third parties."}
                />
                </div>
            </div>
        </div>
    );
}

function TestimonialsSection() {
    const imgClass = "object-contain h-12 my-4 mx-auto"
    return (
        <div className="bg-transparent outline outline-1 outline-gray-200 py-6 my-6 px-8">
            <div className='container mx-auto'>
                <div className="flex-col align-center">
                    <h4 className="text-center text-sm text-black pb-2">
                        We are supported by&nbsp;
                        <Link to="https://startups.microsoft.com"
                            className="text-center text-sm text-black hover:text-gray-800 pb-2">
                        Microsoft for  Startups Founders Hub
                        </Link>
                    </h4>
                    <div className="grid md:grid-cols-4 grid-cols-2 gap-4 justify-content-center mx-auto">
                        <img className={imgClass} alt="Zanista AI" src={zanistaImg}/>
                        <img className={imgClass} alt="Microsoft for Startups Founders Hub" src={msFoundersImg}/>
                        <img className={imgClass} alt="Microsoft Azure" src={azureImg}/>
                        <img className={imgClass} alt="Microsoft" src={microsoftImg}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FAQSection() {
    return (
        <div className="bg-transparent py-6 px-8">
            <div className='container mx-auto'>
                <div className="flex-col align-center md:w-[75%] w-full mx-auto">
                    <h4 className="md:text-center text-start text-lg sm:text-xl text-black kanit-regular mb-10">
                        Frequently Asked Questions
                    </h4>
                    <ul className="flex-col justify-start items-start gap-6">
                        <QuestionItem question="How much does GoodPoint cost?" answer="Our pricing is based on the number of students and the number of exams you want to grade."/>
                        <QuestionItem question="How does GoodPoint work?" answer="GoodPoint carries out many detailed steps including: Pre-processing, OCR Extraction, Rubric Enhancement, Marking and Feedback."/>
                        <QuestionItem question="Is GoodPoint accurate?" answer="GoodPoint's methods and results are triple checked to be as accurate as possible."/>
                        <QuestionItem question="Is my data safe with GoodPoint?" answer="At Zanista AI, we ensure GDPR-compliant data protection, emphasizing strict security measures without saving or sharing user data with third parties."/>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function QuestionItem({ question, answer }) {
    return (
        <li>
            <h4 className="text-sm sm:text-md text-gray-600 mb-1">
                {question}
            </h4>
            <p className="text-xs sm:text-sm">
                {answer}
            </p>
        </li>
    )
}

function TryNowSection() {
    return (
        <div className='px-4'>
        <div className="container mx-auto text-black rounded py-12">
            <div className="flex-col justify-content-center items-start pb-6">
                <h2 className="py-2 text-start text-xl sm:text-2xl kanit-regular">
                    Try GoodPoint Now
                </h2>
                <p className="text-start text-sm sm:text-base text-gray-600">
                    A fully automated system that's capable of grading school
                     and university exam papers in STEM subjects. 
                </p>
            </div>
            <div className="flex-row gap-4">
                <ContactPopup subject={Subject.DEMO}>
                    <button className="bg-orange-300 text-sm md:text-lg hover:bg-orange-400 text-black shadow-lg outline outline-2 rounded">
                        Request a demo
                    </button>
                </ContactPopup>
                <Link to="products/goodpoint">
                    <button className="bg-transparent text-sm md:text-lg hover:bg-orange-400 text-black shadow-lg outline outline-2 rounded">
                        Learn more
                    </button>
                </Link>
            </div>
        </div>
        </div>
    );
}



export default Home;
