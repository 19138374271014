import { useState } from "react";
import Popup from "reactjs-popup";
import { Trash } from "iconoir-react";
import useDeleteAssessment from "src/pages/goodpoint/dashboard/useDeleteAssessment";

import "./DeleteAssessmentButton.css";

export default function DeleteAssessmentButton({ assessmentItem }) {
    const { deleteAssessment, deleting } = useDeleteAssessment();

    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <div className="delete-button">
            <button className="clickable border-mid rounded-small fill-height" onClick={() => setPopupOpen(true)} >
                <Trash />
            </button>
            <Popup open={popupOpen} closeOnDocumentClick={false} onClose={() => setPopupOpen(false)}>
                <div className='delete-modal bg-white rounded-small'>
                    <p>Are you sure you want to delete <b>{assessmentItem != null ? assessmentItem.name : ""}</b>?</p>
                    { deleting 
                        ? <p>Deleting...</p> 
                        : 
                            <div className="flex-row gap-mid">
                                <button className="clickable bg-orange-mid border-mid rounded-small" onClick={
                                    () => deleteAssessment(assessmentItem.id, () => setPopupOpen(false))
                                }>
                                    Yes
                                </button>
                                <button className="clickable bg-yellow-light border-mid rounded-small" onClick={() => setPopupOpen(false)}>
                                    No
                                </button>
                            </div>
                    }
                </div>
            </Popup>
        </div>
    )
}