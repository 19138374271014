// import { replacer, reviver } from "src/util/Json";

export const StorageKeys = ({
    ASSESSMENTS: "assessment-descs",
    ASSESSMENT_OBJECTS: "assessment-objects",
    ASSESSMENT_TABS: "assessment-tabs",
    RUBRICS: "rubrics",
    STUDENT_ANSWERS: "student-answers",
    STUDENT_LIST: "students-list",
    GRADING_RESULTS: "grading-results"
});

export function getFromLocalStorage(storageKey, defaultValue=null) {
    return defaultValue;
    // const value = localStorage.getItem(storageKey);
    // return value ? JSON.parse(value, reviver) : defaultValue;
}

export function saveToLocalStorage(storageKey, value) {
    return value;
    // localStorage.setItem(storageKey, JSON.stringify(value, replacer));
    // return value
}