import { useMemo } from "react";
import Loading from "src/components/ui/Loading";
import { ExamStructure } from "src/pages/goodpoint/assessment/components/structure/ExamStructure";
import QuestionFeedbackItem from "src/pages/goodpoint/assessment/tabs/grading/class-performance/question-feedback/QuestionFeedbackItem";


export default function QuestionFeedbackStructure({ 
    questionFeedback, 
    isFilterOnlyMistakes = false
}) {
    const numAnswers = useMemo(() => {
        return Object.keys(questionFeedback ?? {}).length;
    }, [questionFeedback]);

    function QuestionFeedbackHeader() {
        return <div className="flex-row gap-mid">
            <p>{numAnswers} Question{(numAnswers > 1) ? "s" : ""}</p>
        </div>
    }

    if (!questionFeedback) {
        return <Loading />
    }

    return (
        <ExamStructure
            structureMap={questionFeedback}
            HeaderComponent={QuestionFeedbackHeader}
            ItemComponent={QuestionFeedbackItem}
            initiallyExpanded={true}
            itemCommonProps={{isFilterOnlyMistakes}}
        />
    )
}