import "./Products.css"
import pipelineImg from 'src/assets/home/dissertation-pipeline.jpg';
//import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import FeatureItem from 'src/pages/home/components/FeatureItem';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';
import heroImg from 'src/assets/home/dissertation-hero.jpg';

const demoPlaceholder = "I would like to set up a short meeting to find out more about AI Dissertation Co-Pilot. Please reach out to me by email."

function DissertationCopilotProduct() {
    return (
        <div>
            <ProductHeroSection
                title="AI Dissertation Copilot"
                subtitle="Automated Academic Insights"
                image={heroImg} square
                CTASection={CTASection}
            />
            <FeaturesSection />
            {
                //<HowItWorksSection />
            }
            <TryNowSection />
        </div>
    );
}

const CTASection = () => {
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className="clickable bg-orange-300 hover:bg-orange-300 text-black outline outline-2 rounded shadow-xl">
                    Request a demo
                </button>
            </ContactPopup>
        </div>
    )
}

function FeaturesSection() {
    return (
        <div className="flex-col justify-start items-start pb-4">
            <div className="flex-col gap-6 pb-6">
                <FeatureItem title="Advanced Curation" text="Utilizing AI, we scan thousands of research papers across the internet within a specified subject and timeframe, selectively compiling top-tier publications from renowned journals and authors at leading institutions." />
                <FeatureItem title="Intelligent Summarization" text="We employ LLMs to concisely summarize key findings, offering an insightful and fully automated newsletter containing the latest academic breakthroughs." />
            </div>
            <div>
                <img src={pipelineImg} alt="Exam Master Pipeline" className="w-full"/>
            </div>
        </div>
    );
}

// function HowItWorksSection() {
//     return (
//         <div className="flex-col justify-start items-start pb-4">
//             <div className="mx-auto w-full max-w-[800px] aspect-video rounded">
//                 <ReactPlayer 
//                     url="https://www.youtube.com/watch?v=TODO"
//                     width={"100%"}
//                     height={"100%"}
//                     controls
//                 />
//             </div>
//         </div>
//     );
// }

function TryNowSection() {
    return (
        <div className="flex-col justify-start items-start py-12">
            <div>
                <h2 className="py-2 font-bold text-2xl kanit-regular">
                    Try AI Dissertation CoPilot Now
                </h2>
                <span className="text-gray-600 text-sm">
                    Request a demo to see how our AI Dissertation CoPilot works.
                </span>
            </div>
            <div className="flex-row justify-content-center py-4 gap-4">
                <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                    <button className="bg-orange-300 hover:bg-orange-400 text-black outline outline-2 rounded">
                        Request a demo
                    </button>
                </ContactPopup>
            </div>
        </div>
    )
}

export default DissertationCopilotProduct;