import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";

import "./ClassStats.css";
import ClassGeneralFeedback from "src/pages/goodpoint/assessment/tabs/grading/class-performance/ClassGeneralFeedback";
import { AiGenerated } from "src/pages/goodpoint/assessment/components/structure/StructureSection";


function ClassResults({ examResults }) {    
    return <>
        <div className="flex-row justify-content-space-between">
            <div className="flex-row align-baseline gap-small">
                <b>Class Results</b>
                <AiGenerated />
            </div>
            <p>Statistics for the whole class</p>
        </div>
        <div className="class-average flex-row align-center">
            <h1>{examResults && parseFloat(examResults.class_percent?.toFixed(1))}%</h1>
            <div>
                <p>{examResults?.class_mark.toFixed(1) ?? undefined}/{examResults?.total_mark} Marks</p>
                <p>Grade {examResults?.class_grade}</p>
            </div>
        </div>
    </>;
}

export default function ClassStats({ examResults }) {
    const {assessmentObject} = useContext(AssessmentContext);
    
    const callApi = useCallApi();
    
    const [classDistImgSrc, setClassDistImg] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if (classDistImgSrc !== null) return;
        callApi("GET", GoodPointApi.Grading.Images(assessmentObject.id, GradingImages.OVERALL_DIST))
            ?.then(response => {
                if (response.status === 200) {
                    response.blob().then(img => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setClassDistImg(reader.result)
                        }
                        reader.readAsDataURL(img)
                    })
                }
            });
    }, [callApi, assessmentObject, classDistImgSrc])


    return <>
        <div className="class-stats border-mid rounded-small fill-width padding-large">
            <ClassResults examResults={examResults} />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
            <img className="cursor-pointer" src={classDistImgSrc} alt="Class Overall Marks Distribution" onClick={
                () => { setPopupOpen(true); }
            }/>
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
            <ClassGeneralFeedback />
        </div>

        <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
            <div className='bg-white rounded-small'>
                <img src={classDistImgSrc} alt="Class Overall Marks Distribution" />
            </div>
        </Popup>
    </>
}