import { createContext, useCallback, useEffect, useReducer, useState } from "react";

export const Visibility = ({
    HIDDEN: 0,
    PARTIALLY_VISIBLE: 1,
    PARTIALLY_HIDDEN: 2,
    VISIBLE: 3,
});

export const VisibilityContext = createContext({
    visibilityMap: {},
    setVisibility: (_tagList, _vis) => {},
    getVisibility: (_tagList) => Visibility.VISIBLE,
    getChildrenVisibility: (_tagList) => {},
    clear: (_tagList=null) => {},
    hiddenCount: 0
})

function createVisibilityMap(examStructureMap) {
    function recurse(map) {
        const result = Object.fromEntries(
            Object
                .entries(map)
                .map(([_key, val]) => {
                    const visQ = {};
                    if ("sub_questions" in val && Object.keys(val["sub_questions"]).length > 0) {
                        visQ.subQuestions = recurse(val["sub_questions"]);
                    }
                    visQ.visibility = Visibility.VISIBLE;
                    return [val.tag, visQ];
                })
        );
        return result;
    }
    const result = recurse(examStructureMap);
    return result;
}

function visibilityReducer(visibilityMap, action) {
    const result = {...visibilityMap};
    switch (action.type) {
        case "hide": 
        case "show": {
            var visQ = result;
            for (const [i, tag] of action.tagList.entries()) {
                if ("visibility" in visQ && action.type === "show") {
                    visQ.visibility = Math.max(visQ.visibility, Visibility.PARTIALLY_VISIBLE);
                }
                if (i > 0) {
                    visQ = visQ.subQuestions;
                }
                visQ = visQ[tag];
            }
            const visible = (action.type === "show");
            if (!visible) {
                setVisibilityAll(visQ.subQuestions, Visibility.HIDDEN);
                visQ.visibility = Visibility.HIDDEN;
            } else {
                setVisibilityAll(visQ.subQuestions, Visibility.VISIBLE);
                visQ.visibility = Visibility.VISIBLE;
            }
            break;
        }
        case "clear": {
            if (action.tagList) {
                const visQ = getFromMap(result, action.tagList);
                setVisibilityAll(visQ.subQuestions, Visibility.VISIBLE);
                visQ.visibility = Visibility.VISIBLE;
            } else {
                setVisibilityAll(result, Visibility.VISIBLE);
            }
            break;
        }
        default: {
            break;
        }
    }
    return result;
}

function getFromMap(visibilityMap, tagList) {
    var visQ = visibilityMap;
    for (const [i, tag] of tagList.entries()) {
        if (i > 0) {
            visQ = visQ.subQuestions;
        }
        visQ = visQ[tag];
    }
    return visQ;
}

function getVisibilityFromMap(visibilityMap, tagList) {
    return getFromMap(visibilityMap, tagList).visibility;
}

function getChildrenVisibilityFromMap(visibilityMap, tagList) {
    const visQ = getFromMap(visibilityMap, tagList);
    const result = {};
    if ("subQuestions" in visQ) {
        Object
            .entries(visQ.subQuestions)
            .forEach(([subQ, val]) => {
                result[subQ] = val.visibility;
            });
    }
    return result;
}

function setVisibilityAll(visibilityMap, visible) {
    if (!visibilityMap) return;
    for (const val of Object.values(visibilityMap)) {
        if ("visibility" in val) {
            val.visibility = visible;
        }
        if ("subQuestions" in val) {
            setVisibilityAll(val.subQuestions, visible);
        }
    }
}

function countHiddenFromMap(visibilityMap) {
    var count = 0;
    if (visibilityMap) {
        for (const val of Object.values(visibilityMap)) {
            if ("subQuestions" in val) {
                count += countHiddenFromMap(val.subQuestions);
            }
            count += (val.visibility in [Visibility.HIDDEN, Visibility.PARTIALLY_HIDDEN]) ? 1 : 0;
        }
    }
    return count;
}

export function useVisibility(examStructureMap) {
    const [visibilityMap, dispatch] = useReducer(
        visibilityReducer,
        createVisibilityMap(examStructureMap)
    );

    const setVisibility = useCallback(
        (tagList, vis) => dispatch({type: vis ? "show" : "hide", tagList}),
        []
    );

    const getVisibility = useCallback(
        (tagList) => getVisibilityFromMap(visibilityMap, tagList),
        [visibilityMap]
    );

    const getChildrenVisibility = useCallback(
        (tagList) => getChildrenVisibilityFromMap(visibilityMap, tagList),
        [visibilityMap]
    )

    const clear = useCallback(
        (tagList=null) => dispatch({type: "clear", tagList}),
        []
    );

    const [hiddenCount, setHiddenCount] = useState(0);
    useEffect(() => {
        const count = countHiddenFromMap(visibilityMap);
        setHiddenCount(count);
    }, [visibilityMap]);

    return {
        visibilityMap,
        setVisibility,
        getVisibility,
        getChildrenVisibility,
        hiddenCount,
        clear
    }
}