import { useEffect, useState, useContext, useCallback } from "react";
import Title from "src/components/content/Title";
import FileUploader from "src/components/ui/FileUploader";
import useCallApi from "src/api/useCallApi";
import { LogType, StepError, StepState } from "src/api/goodpoint/Const";
import LogProgress from "src/components/ui/LogProgress";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import AnswersContent from "src/pages/goodpoint/assessment/tabs/answers/AnswersContent";
import DownloadButton from "src/components/ui/DownloadButton";
import { LogUpdateContext } from "src/pages/goodpoint/assessment/LogUpdateContext";


function DownloadAllAnswers({ assessmentId, ...props }) {
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.StudentAnswers.Download(assessmentId, "ocr")}
            filename={`${assessmentId}_all_student_answers.zip`}
            className="footer-control"
            text={`Download All Student Answers`}
            {...props}
        />
    )
}

function AnswersTab() {
    const {assessmentObject, updateAssessmentObject, setFooterControls} = useContext(AssessmentContext);
    const callApi = useCallApi();

    const [logUpdate, setLogUpdate] = useState([{
        message: "Starting...",
        type: LogType.INFO.toUpperCase(),
        progress: "NULL",
    }]);
    const [isFinished, setIsFinished] = useState(assessmentObject.student_answers_step !== StepState.PROCESSING);
    const [processingError, setProcessingError] = useState(null);

    useEffect(() => {
        if (assessmentObject.student_answers_step !== StepState.READY) return;
        console.log("Processing Student Answers");
        setIsFinished(false)
        callApi("POST", GoodPointApi.StudentAnswers.Process(assessmentObject.id))?.then(response => {
            if (response.status === 200) {
                updateAssessmentObject({
                    student_answers_step: StepState.PROCESSING
                });
            } else {
                response.json().then(body => {
                    if (!("error" in body && body["error"] === "Invalid request order")) {
                        updateAssessmentObject({
                            student_answers_step: StepState.AVAILABLE
                        });
                    }
                })
            }
        });
    }, [callApi, assessmentObject, updateAssessmentObject]);


    const getLogs = useCallback(() => {
        callApi("GET", GoodPointApi.StudentAnswers.Logs(assessmentObject.id))?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const logs = body["logs"]
                    setLogUpdate(logs);

                    if (body["finished"]) {
                        console.log("finished")
                        setIsFinished(true);
                    }

                    for (let i = 0; i < logs.length; i++) {
                        const log = logs[i];
                        console.log(log)
                        if (log.type.toLowerCase() === LogType.ERROR.toLowerCase()) {
                            updateAssessmentObject({
                                student_answers_step: StepState.READY,
                                grading_step: StepState.NOT_AVAILABLE
                            });
                            break;
                        } else if (log.type.toLowerCase() === LogType.SUCCESS.toLowerCase()){
                            updateAssessmentObject({
                                student_answers_step: StepState.COMPLETED,
                                grading_step: StepState.PROCESSING
                            });
                        } else if (log.type.toLowerCase() === LogType.FAIL.toLowerCase()) {
                            if (log.message.toLowerCase().includes("not enough credits")) {
                                setProcessingError(StepError.INSUFFICIENT_CREDITS)
                            }
                            updateAssessmentObject({
                                student_answers_step: StepState.AVAILABLE,
                                grading_step: StepState.NOT_AVAILABLE
                            });
                            break;
                        }
                    }
                });
            }
        });
    }, [assessmentObject.id, callApi, updateAssessmentObject]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isFinished) {
                getLogs();
            } else {
                clearInterval(intervalId);
            }
        }, 4000);
    
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [getLogs, isFinished]);

    useEffect(() => {
        if (assessmentObject.student_answers_step < StepState.COMPLETED) {
            setFooterControls(null);
        } else {
            setFooterControls([
                <DownloadAllAnswers key={0} assessmentId={assessmentObject.id}/>
            ])
        }
    }, [assessmentObject.student_answers_step, assessmentObject.id, setFooterControls])

    const content = (assessmentObject) => {
        switch (assessmentObject.student_answers_step) {
            case StepState.AVAILABLE:
                switch (processingError) {
                    case StepError.INSUFFICIENT_CREDITS:
                        return <p>Insufficient funds to process student answers.</p>
                    case null:
                        return <FileUploader
                            title="Upload Student Answers" 
                            text="(multiple) .pdf"
                            uploadEndpoint={GoodPointApi.StudentAnswers.Upload(assessmentObject.id)}
                            onUpload={() => { updateAssessmentObject({ student_answers_step: StepState.READY}) }}
                            formDataKey="student-answers"
                        />
                    default:
                        return <p>Processing error.</p>
                }
            case StepState.PROCESSING:
                return <LogProgress text={"Processing student answers..."} />
            case StepState.COMPLETED:
                return <AnswersContent />
            default:
                return null
        }
    }

    return (
        <div className='answers-tab'>
            <Title
                Size='h2'
                title="Student Answers"
                subtitle={
                    assessmentObject.student_answers_step === StepState.COMPLETED
                        ? `Review your students' answers`
                        : `Upload your students' answers for this assessment`
                }
            />
            <LogUpdateContext.Provider value={{ logUpdate, setLogUpdate }}>
                {content(assessmentObject)}
            </LogUpdateContext.Provider>
        </div>
    );
}


export default AnswersTab;
