import { useMsal } from "@azure/msal-react";
import useCallApi from "../useCallApi";
import { protectedResources } from "src/auth/AuthConfig";
import { useCallback } from "react";

export default function useNewsWitchApi(config=null) {
    const { instance } = useMsal();
    const callAPI = useCallApi({
        ...config,
        base_endpoint: protectedResources.apiNewsWitch.endpoint
    });

    return useCallback((method=null, endpoint=null, data=null) => {
        const headers = data?.headers ?? [];
        if (protectedResources.apiNewsWitch.endpoint.includes("localhost")) {
            headers.push({"X-User-ID": instance.getActiveAccount().idTokenClaims['extension_UserID']});
        }
        return callAPI(method, endpoint, {...data, headers});
    }, [callAPI, instance]);
}
