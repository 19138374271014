import './Dashboard.css';
import Title from 'src/components/content/Title';
import Footer from 'src/pages/dashboard/Footer';
import { ProductCard, ComingSoonCard } from 'src/pages/dashboard/ProductCard';
import { EDTECH_PRODUCTS, FINANCE_PRODUCTS } from './EdtechProducts';

export const PRODUCT_COLS = 4
export const MIN_PRODUCTS = 3

export default function Dashboard() {

    const allProducts = [...EDTECH_PRODUCTS, ...FINANCE_PRODUCTS]
    
    return <div className='dashboard'>
        <div className="centered main">
            <div className="container" id="main-dashboard">
                <Title
                    title="Dashboard"
                    subtitle="Select a product to get started"
                />
                <div className="product-panel flex-col">
                {
                        Array(MIN_PRODUCTS).fill().map((_, i) => {
                            return (i < allProducts.length)
                                ? <ProductCard key={i} {...allProducts[i]} index={i}/>
                                : <ComingSoonCard key={i} index={i}/>
                        })
                }
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}