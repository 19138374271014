import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";

import 'src/index.css';
import 'src/styles/Main.css'
import 'src/styles/Layout.css'
import 'src/styles/Font.css'
import 'src/styles/Visual.css'
import 'src/styles/Button.css'

import ZanistaRouter from './Router';

import MsalInstance from 'src/auth/Msal';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={
            ZanistaRouter({ msal: MsalInstance })
        } />
    </React.StrictMode>
);
