import { useMemo } from "react";
import Latex from "react-latex-next";
import Divider from "src/components/layout/Divider";
import { StructureItem } from "src/pages/goodpoint/assessment/components/structure/StructureItem";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";


function AnswerHeader({ itemData, nestingLevel }) {
    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_questions;
        return Object.keys(subQuestions ?? {}).length;
    }, [itemData.sub_questions]);

    return <div className="answers-item-header fill-width">
        <p className="answers-item-question-tag justify-self-left">
            <b>{nestingLevel > 0 ? "Part " : ""}{itemData.tag}</b>
        </p>
        {
            (numParts > 0) && <p className="answers-item-parts justify-self-right">{numParts} Parts</p>
        }
    </div>
}

function AnswerItemContent({ itemData }) {
    return <div className="student-answer-content">
        {
            itemData.student_solution &&
                <StructureSection heading={"Answer"}>
                    <Latex>{itemData.student_solution}</Latex>
                </StructureSection>
        }
        {
            !(itemData.student_solution || Object.keys(itemData.sub_questions).length > 0) &&
                <>
                    <p>(empty)</p>
                    <Divider lineColour='rgba(0, 0, 0, 0.3)' shadowColour='rgba(0, 0, 0, 0.20)'/>
                </>
        }
    </div>
}

export default function AnswerItem(props) {
    return <StructureItem
        HeaderComponent={AnswerHeader}
        ContentComponent={AnswerItemContent}
        {...props}
    />
}