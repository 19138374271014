import { getAssessmentObjects, setAssessmentObjects } from "src/storage/AssessmentObject";
import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

// Returns an array containing all assessmentObjects in localStorage
export function getAssessmentDescs() {
    return getFromLocalStorage(StorageKeys.ASSESSMENTS, {});
}

export function setAssessmentDescs(assessmentDescs) {
    saveToLocalStorage(StorageKeys.ASSESSMENTS, assessmentDescs);
}

export function getAssessmentsCount() {
    return Object.keys(getAssessmentDescs()).length;
}

// Returns undefined if there is no assessmentObject with id
export function getAssessmentDesc(assessmentObject) {
    return getAssessmentDescById(assessmentObject.id);
}

export function getAssessmentDescById(id) {
    return getAssessmentDescs()[id];
}

export function setAssessmentDesc(assessmentDesc) {
    var items = getAssessmentDescs();
    items[assessmentDesc.id] = assessmentDesc;
    setAssessmentDescs(items);
}

export function deleteLocalAssessment(id) {
    var newAssessmentDescs = getAssessmentDescs();
    var newAssessmentObjects = getAssessmentObjects();
    delete newAssessmentDescs[id];
    delete newAssessmentObjects[id];
    setAssessmentDescs(newAssessmentDescs);
    setAssessmentObjects(newAssessmentObjects);
    return newAssessmentDescs;
}

export function updateLocalAssessments(assessmentsUpdate) {
    var newAssessments = {};
    var oldAssessments = getAssessmentDescs();
    // Delete localStorage assessments which are no longer present server-side
    for (const [id, examDesc] of Object.entries(oldAssessments)) {
        if (id in assessmentsUpdate) {
            if (examDesc.last_modified > assessmentsUpdate[id].last_modified) {
                newAssessments[id] = examDesc;
            }
        }
    }
    // Add new assessments that were created server-side
    for (const [id, updateDesc] of Object.entries(assessmentsUpdate)) {
        if (!(id in newAssessments)) {
            newAssessments[id] = updateDesc;
        }
    }
    setAssessmentDescs(newAssessments);
    return newAssessments;
}