import { useContext, useMemo } from "react"
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext"

import "./StudentsMenu.css";
import Loading from "src/components/ui/Loading";


export function StudentButton({ studentData }) {
    const {selectedStudent, setSelectedStudent} = useContext(StudentsContext);
    const isSelected = selectedStudent?.student_id === studentData.student_id;
    const questionsAnswered = Object.keys(studentData.student_answers).length;

    return (
        <button 
            className={
                `student-button fill-width clickable rounded-small padding-small border-thin outline-thin ` + 
                `${isSelected ? "bg-orange-mid outline-black" : "bg-yellow-light outline-transparent"}`
            }
            onClick={() => setSelectedStudent(studentData)}
        >
            <div className={`student-button-grid fill-width`}>
                <p className="student-name justify-self-left"><b>{studentData.name ?? studentData.student_id}</b></p>
                {
                    (studentData.name) && <p className="student-id">{studentData.student_id}</p>
                }
                <p className="questions-answered justify-self-right">
                    {questionsAnswered} question{(questionsAnswered > 1) ? "s" : ""} answered
                </p>
            </div>
        </button>
    )
}

export function StudentsMenu({ style={}, StudentButtonComponent=StudentButton, extraProps={} }) {
    const {students} = useContext(StudentsContext);
    
    const studentsList = useMemo(() => {
        return Object
            .entries(students ?? {})
            .map(([_studentId, studentData], i) => {
                return <StudentButtonComponent
                    key={i}
                    studentData={studentData}
                    {...extraProps}
                />
            });
    }, [students, extraProps]);

    if (!students) {
        return <Loading />
    }

    return <div className="students-list flex-col gap-mid justify-content-left" style={style}>
        {studentsList}
    </div>
}