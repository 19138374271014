import { useContext, useMemo } from "react";
import Latex from "react-latex-next";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import SolutionSteps from "src/pages/goodpoint/assessment/tabs/rubric/structure/SolutionSteps";

import "./RubricItem.css"
import { StructureItem } from "src/pages/goodpoint/assessment/components/structure/StructureItem";
import { ExamStructureContext } from "src/pages/goodpoint/assessment/components/structure/ExamStructureContext";


function RubricItemHeader({ itemData, nestingLevel }) {
    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_questions;
        return Object.keys(subQuestions ?? {}).length;
    }, [itemData.sub_questions]);

    return (
        <div className={`flex-row gap-mid fill-width`}>
            <div className="rubric-item-header fill-width">
                <p className="rubric-item-question-tag justify-self-left"><b>
                    {nestingLevel > 0 ? "Part " : ""}{itemData.tag}
                </b></p>
                <p className="rubric-item-parts justify-self-left">
                {
                    (numParts > 0) && `${numParts} Parts`
                }
                </p>
                <p className="rubric-item-marks justify-self-right">{itemData.mark} Marks</p>
            </div>
        </div>
    );
}

function RubricItemContent({ itemData }) {
    const enhanced = useContext(ExamStructureContext).itemCommonProps.enhanced;
    return <div className={`rubric-item-content`}>
        {
            itemData.question_text && 
                <StructureSection heading={"Question"}>
                    <Latex>{itemData.question_text}</Latex> 
                </StructureSection>
        }
        {
            enhanced &&
            itemData.description &&
                <StructureSection heading={"Description"} aiGenerated>
                    <Latex>{itemData.description}</Latex>
                </StructureSection>
        }
        {
            enhanced &&
            itemData.result &&
                <StructureSection heading={"Result"} aiGenerated>
                    <Latex>{itemData.result}</Latex>
                </StructureSection>
        }
        {
            itemData.solution_text &&
                <StructureSection heading={"Solution"}>
                    <Latex>{itemData.solution_text}</Latex>
                </StructureSection>
        }
        {
            enhanced &&
            itemData.steps &&
                <SolutionSteps steps={itemData.steps}/>
        }
    </div>
}

export function RubricItem(props) {
    return <StructureItem
        HeaderComponent={RubricItemHeader}
        ContentComponent={RubricItemContent}
        {...props}
    />
}