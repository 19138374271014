import "./Products.css"
import pipelineImg from 'src/assets/home/exam-master-pipeline.jpg'
//import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from "src/pages/home/components/ContactPopup";
import FeatureItem from 'src/pages/home/components/FeatureItem';
import heroImg from 'src/assets/home/exam-hero.jpg';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';

const demoPlaceholder = "I would like to set up a short meeting to find out more about AI Exam Master. Please reach out to me by email."

function ExamMasterProduct() {
    return (
        <div>
            <ProductHeroSection 
                title="AI Exam Master" 
                subtitle="Custom problem sets and solutions for an enhanced learning experience" 
                image={heroImg} square
                CTASection={CTASection}
            />
            <FeaturesSection />
            {
                //<HowItWorksSection />
            }
            <TryNowSection />
        </div>
    );
}

const CTASection = () => {
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className="clickable bg-orange-300 hover:bg-orange-300 text-black outline outline-2 rounded shadow-xl">
                    Request a demo
                </button>
            </ContactPopup>
        </div>
    )
}

function FeaturesSection() {
    return (
        <div className="flex-col justify-start items-start pb-4">
            <div className="flex-col gap-6 pb-6">
                <FeatureItem title="AI-Powered Creation" text="Custom problem sets generated with Wolfram Alpha-powered solutions" />
                <FeatureItem title="Smart Adaptation" text="Question difficulty automatically adjusted and progress tracked for optimized learning." />
                <FeatureItem title="Real Time Collaborative Learning" text="Group study support and classroom interaction for collective education." />
            </div>
            <div>
                <img src={pipelineImg} alt="Exam Master Pipeline" className="w-full"/>
            </div>
        </div>
    );
}


// function HowItWorksSection() {
//     return (
//         <div className="flex-col justify-start items-start pb-4">
//             <div className="mx-auto w-full max-w-[800px] aspect-video rounded">
//                 <ReactPlayer 
//                     url="https://www.youtube.com/watch?v=TODO"
//                     width={"100%"}
//                     height={"100%"}
//                     controls
//                 />
//             </div>
//         </div>
//     );
// }

function TryNowSection() {
    return (
        <div className="flex-col justify-start items-start py-12">
            <div>
                <h2 className="py-2 font-bold text-2xl kanit-regular">
                    Try AI Exam Master Now
                </h2>
                <span className="text-gray-600 text-sm">
                    Request a demo to receive custom problem sets and solutions powered by AI.
                </span>
            </div>
            <div className="flex-row justify-content-center py-4 gap-4">
                <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                    <button className="bg-orange-300 hover:bg-orange-400 text-black outline outline-2 rounded">
                        Request a demo
                    </button>
                </ContactPopup>
            </div>
        </div>
    )
}

export default ExamMasterProduct;