import { Eye, EyeClosed, EyeSolid, NavArrowDown, NavArrowRight, Prohibition, Xmark } from "iconoir-react";
import { useContext, useEffect, useMemo } from "react";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExamStructureContext } from "src/pages/goodpoint/assessment/components/structure/ExamStructureContext";
import { SectionHeading } from "src/pages/goodpoint/assessment/components/structure/StructureSection";

import "./StructureItem.css"
import { Visibility, VisibilityContext } from "src/pages/goodpoint/assessment/components/structure/Visibility";


const NESTING_LEVEL_STYLES = [
    "padding-mid bg-orange-light border-mid",
    "padding-mid bg-yellow-light border-mid",
    "padding-small bg-grey-light border-thin"
];

const VISIBILITY_STYLES = {
    [Visibility.HIDDEN]: [[EyeClosed, "gray"], "bg-grey-light outline-transparent"],
    [Visibility.PARTIALLY_HIDDEN]: [[EyeSolid, "gray"], "bg-grey-light outline-transparent"],
    [Visibility.PARTIALLY_VISIBLE]: [[EyeSolid, "var(--orange-dark)"], "bg-orange-light outline-transparent"],
    [Visibility.VISIBLE]: [[Eye, "black"], "bg-yellow-light outline-black"],
}

function ItemVisibleButton({ enabled=true, visibility, toggleIsVisible }) {
    var style;
    if (enabled) {
        style = VISIBILITY_STYLES[visibility];
    } else {
        style = [[Prohibition, "var(--red)"], "bg-red-light outline-transparent"];
    }
    const Icon = style[0][0];
    const iconColour = style[0][1];
    return (
        <div 
            className={
                `item-visible-button clickable rounded-small align-center padding-small cursor-pointer outline-thin ` +
                `${style[1]} ${enabled ? "" : "cursor-disabled translucent"}`
            }
            onClick={() => toggleIsVisible()}
        >
            <Icon color={iconColour} />
        </div>
    )
}

export function DefaultHeader({ itemData, nestingLevel: _nestingLevel }) {
    return itemData.tag ?? "ITEM";
}

export function StructureSubQuestions({ itemData, outerTags, ...props }) {
    const {ItemComponent, isSelectionMode } = useContext(ExamStructureContext);
    const {clear, getChildrenVisibility} = useContext(VisibilityContext);

    const tagList = [...outerTags, itemData.tag];
    const childrenVisibility = getChildrenVisibility(tagList);
    const hiddenChildCount = Object
        .values(childrenVisibility)
        .filter(v => (v === Visibility.HIDDEN) || (v === Visibility.PARTIALLY_HIDDEN))
        .length;
    
    return <div className="flex-col gap-mid">
        {
            (outerTags.length === 0) &&
            itemData.sub_questions &&
            Object.keys(itemData.sub_questions).length > 0 &&
                <SectionHeading heading={"Sub Questions"} />
        }
        {
            (hiddenChildCount > 0) && (!isSelectionMode) &&
                <div 
                    className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                    onClick={() => clear(tagList)}
                >
                    <p>{hiddenChildCount} hidden (Clear)</p>
                    <Xmark strokeWidth={1.0} />
                </div>
        }
        {
            itemData.sub_questions &&
                Object.entries(itemData.sub_questions).map(([subKey, subData]) => {
                    return <ItemComponent 
                        key={`[${outerTags}, ${subKey}]`} 
                        itemData={subData}
                        outerTags={tagList}
                        {...props}
                    />
                })
        }
    </div>
}

export function StructureItem({
    itemData,
    outerTags=[],
    HeaderComponent=DefaultHeader,
    ContentComponent=null,
    SubItemsComponent=StructureSubQuestions,
}) {
    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed
    } = useContext(ExpandCollapseContext);

    const {isExpanded, expand, collapse, toggle} = useExpandCollapse();

    useEffect(() => { isAllExpanded && expand(); },    [isAllExpanded, expand]);
    useEffect(() => { isAllCollapsed && collapse(); }, [isAllCollapsed, collapse]);

    const { isSelectionMode, searchTerms, clearSearch, searchVisibility } = useContext(ExamStructureContext);
    const { getVisibility, setVisibility } = useContext(VisibilityContext);
    const { getVisibility: getSearchVisibility, setVisibility: setSearchVisibility } = searchVisibility;

    const tagList = useMemo(() => [...outerTags, itemData.tag], [itemData.tag, outerTags]);
    const tagListText = useMemo(() => tagList.join("."), [tagList]);
    const visibility = getVisibility(tagList);
    const visibilityFromSearch = (searchTerms.length === 0) ? Visibility.VISIBLE : getSearchVisibility(tagList);
    const show = [visibility, visibilityFromSearch].filter(v => [Visibility.PARTIALLY_VISIBLE, Visibility.VISIBLE].includes(v)).length === 2;

    useEffect(() => {
        if (!searchTerms || searchTerms.length === 0) return;
        var newSearchVisibility = Visibility.HIDDEN;
        for (const t of [tagListText, tagListText.split("Question ", 2).filter(s => s.length > 0)[0]]) {
            if (searchTerms.findIndex(s => t.startsWith(s)) !== -1) {
                newSearchVisibility = Visibility.VISIBLE;
            }
        }
        if (newSearchVisibility !== Visibility.VISIBLE) {
            for (const val of Object.values(itemData)) {
                if (newSearchVisibility === Visibility.VISIBLE) {
                    break;
                }
                const type = typeof val;
                if (type === "string") {
                    for (const searchTerm of searchTerms) {
                        if (val.indexOf(searchTerm) !== -1) {
                            newSearchVisibility = Visibility.VISIBLE;
                            break;
                        }
                    }
                }
            }
        }
        if (newSearchVisibility !== visibilityFromSearch) {
            setSearchVisibility(tagList, newSearchVisibility);
        }
    }, [itemData, searchTerms, setSearchVisibility, tagList, tagListText, visibilityFromSearch]);

    return (
        <div className={`${(show || isSelectionMode) ? "" : "display-none"}`}>
            <div 
                className={`flex-col gap-large`}
                style={{"marginBottom": isExpanded ? "20px" : "00px"}}
            >
                <div className="structure-item-bar flex-row gap-large align-center">
                    {
                        isSelectionMode
                            ? <ItemVisibleButton 
                                enabled={[Visibility.VISIBLE, Visibility.PARTIALLY_VISIBLE].includes(visibilityFromSearch)}
                                visibility={visibility} 
                                toggleIsVisible={
                                    () => (visibility < Visibility.VISIBLE) 
                                        ? setVisibility(tagList, Visibility.VISIBLE)
                                        : setVisibility(tagList, Visibility.HIDDEN)
                                }
                            />
                            : null
                    }
                    {
                        [Visibility.PARTIALLY_HIDDEN, Visibility.HIDDEN].includes(visibilityFromSearch) &&
                            <div 
                                className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer text-nowrap"
                                onClick={clearSearch}
                            >
                                <p className="text-nowrap">Clear search</p>
                                <Xmark strokeWidth={1.0} />
                            </div>
                    }
                    <button 
                        onClick={() => { toggle(); }}
                        className={
                            `structure-item-button ${(!show) ? "translucent" : ""} clickable rounded-small flex-row fill-width ` + 
                            `${NESTING_LEVEL_STYLES[Math.min(outerTags.length, NESTING_LEVEL_STYLES.length)]}`
                        }
                    >
                        {
                            isExpanded ? <NavArrowDown/> : <NavArrowRight/>
                        }
                        <HeaderComponent itemData={itemData} nestingLevel={outerTags.length}/>
                    </button>
                </div>
                {
                    <div className={`${isExpanded ? "" : "display-none"}`}>
                        <div className={`${(!show) ? "translucent" : ""}`}>
                            { ContentComponent && <ContentComponent itemData={itemData} nestingLevel={outerTags.length} /> }
                        </div>
                        {
                            SubItemsComponent &&
                                <SubItemsComponent 
                                    itemData={itemData}
                                    outerTags={outerTags}
                                />
                        }
                    </div>
                }
            </div>
        </div>
    )
}