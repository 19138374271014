import "./Products.css"
//import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import heroImg from 'src/assets/home/newswitch-hero.png';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';

const demoPlaceholder = "I would like to set up a short meeting to find out more about NewsWitch. Please reach out to me by email."

function NewsWitchProduct() {
    return (
        <div>
            <ProductHeroSection
                title="NewsWitch"
                subtitle="Detailed reports with news summaries for any number of tickers and timeframes"
                image={heroImg}
                CTASection={CTASection}
            />
            {
                // <HowItWorksSection />
            }
            <TryNowSection />
        </div>
    );
}

const CTASection = () => {
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className="clickable bg-orange-300 hover:bg-orange-300 text-black outline outline-2 rounded shadow-xl">
                    Request a demo
                </button>
            </ContactPopup>
        </div>
    )
}

// function HowItWorksSection() {
//     return (
//         <div className="flex-col justify-start items-start pb-4">
//             <div className="mx-auto w-full max-w-[800px] aspect-video rounded">
//                 <ReactPlayer 
//                     url="https://www.youtube.com/watch?v=TODO"
//                     width={"100%"}
//                     height={"100%"}
//                     controls
//                 />
//             </div>
//         </div>
//     );
// }

function TryNowSection() {
    return (
        <div className="flex-col justify-start items-start pb-12">
            <div>
                <h2 className="py-2 font-bold text-2xl kanit-regular">
                    Try NewsWitch Now
                </h2>
                <span className="text-gray-600 text-sm">
                    Request a demo to see how NewsWitch works.
                </span>
            </div>
            <div className="flex-row justify-content-center py-4 gap-4">
                <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                    <button className="bg-orange-300 hover:bg-orange-400 text-black outline outline-2 rounded">
                        Request a demo
                    </button>
                </ContactPopup>
            </div>
        </div>
    )
}

export default NewsWitchProduct;