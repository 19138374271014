import Title from "src/components/content/Title"
import "src/styles/Main.css"
import "./GradingTab.css"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LogType, StepError, StepState } from 'src/api/goodpoint/Const'
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext"
import GoodPointApi from "src/api/goodpoint/GoodPointApi"
import DownloadButton from "src/components/ui/DownloadButton"
import GradingContent from "src/pages/goodpoint/assessment/tabs/grading/GradingContent"
import { LogUpdateContext } from "src/pages/goodpoint/assessment/LogUpdateContext"
import LogProgress from "src/components/ui/LogProgress"
import { PageEdit } from "iconoir-react"
import useCallApi from "src/api/useCallApi"
import DownloadOverallFeedback from "src/pages/goodpoint/assessment/tabs/grading/class-performance/question-feedback/DownloadOverallFeedback"


function DownloadOutput({ assessmentId, ...props }) {
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Output.Download(assessmentId)}
            filename={`${assessmentId}_output.zip`}
            text={`Download All Output`}
            {...props}
        />
    )
}

export default function GradingTab() {
    const {assessmentObject, updateAssessmentObject, setFooterControls} = useContext(AssessmentContext);

    const callApi = useCallApi();

    const [logUpdate, setLogUpdate] = useState([{
        message: "Starting...",
        type: LogType.INFO.toUpperCase(),
        progress: "NULL",
    }])
    const [isFinished, setIsFinished] = useState(assessmentObject.grading_step !== StepState.PROCESSING);
    const [processingError, setProcessingError] = useState(null);

    const startGrading = useCallback(() => {
        setIsFinished(false)
        callApi("POST", GoodPointApi.Grading.Start(assessmentObject.id))?.then(response => {
            if (response.status === 200) {
                updateAssessmentObject({
                    grading_step: StepState.PROCESSING
                })
            } else {
                updateAssessmentObject({
                    grading_step: StepState.AVAILABLE
                })
            }
        })
    }, [callApi, assessmentObject.id, updateAssessmentObject])

    const getLogs = useCallback(() => {
        callApi("GET", GoodPointApi.Grading.Logs(assessmentObject.id))?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const logs = body["logs"]
                    setLogUpdate(logs);

                    if (body["finished"]) {
                        console.log("finished")
                        setIsFinished(true);
                    }

                    for (let i = 0; i < logs.length; i++) {
                        const log = logs[i];
                        // console.log(log)
                        if (log.type.toLowerCase() === LogType.ERROR.toLowerCase()) {
                            updateAssessmentObject({
                                grading_step: StepState.AVAILABLE
                            });
                            break;
                        } else if (log.type.toLowerCase() === LogType.SUCCESS.toLowerCase()){
                            updateAssessmentObject({
                                grading_step: StepState.COMPLETED
                            });
                        } else if (log.type.toLowerCase() === LogType.FAIL.toLowerCase()) {
                            if (log.message.toLowerCase().includes("not enough credits")) {
                                setProcessingError(StepError.INSUFFICIENT_CREDITS)
                            }
                            updateAssessmentObject({
                                grading_step: StepState.AVAILABLE
                            });
                            break;
                        }
                    }
                });
            }
        });
    }, [assessmentObject.id, callApi, updateAssessmentObject])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isFinished) {
                getLogs();
            } else {
                clearInterval(intervalId);
            }
        }, 4000);
    
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [getLogs, isFinished]);

    useEffect(() => {
        if (assessmentObject.grading_step !== StepState.COMPLETED) {
            setFooterControls(null);
        } else {
            setFooterControls([
                <DownloadOutput key={0} assessmentId={assessmentObject.id}/>,
                <DownloadOverallFeedback key={1} assessmentId={assessmentObject.id}/>
            ]);
        }
    }, [assessmentObject.grading_step, assessmentObject.id, setFooterControls])

    const content = useMemo(() => {
        switch (assessmentObject.grading_step) {
            case StepState.AVAILABLE:
            case StepState.READY:
                switch (processingError) {
                    case null:
                        return <>
                            <Title
                                Size="h2"
                                title="Grading"
                                subtitle={`Begin the AI Grading process`}
                            />
                            <button className="begin-grading clickable bg-orange-mid border-mid rounded-big"
                                    onClick={startGrading}>
                                <PageEdit width={150} height={150} strokeWidth={0.5}/>
                                <p className="margin-x-large"><b>Begin Grading!</b></p>
                            </button>
                        </>
                    default:
                        return <p>Insufficient credits to begin assessment grading.</p>
                }
            case StepState.PROCESSING:
                return <LogProgress text={"Grading in progress..."} />
            case StepState.COMPLETED:
                return <GradingContent />
            default:
                return null
        }
    }, [assessmentObject.grading_step, processingError, startGrading])

    return (
        <div className="results-tab">
            <LogUpdateContext.Provider value={{ logUpdate, setLogUpdate }}>
                {content}
            </LogUpdateContext.Provider>
        </div>
    )
}
