import { useCallback, useState } from "react";
import useCallApi from "src/api/useCallApi";

export function useGeneralFileUpload() {
    const callApi = useCallApi();

    const [isUploading, setIsUploading] = useState(false);

    function upload(uploadEndpoint, files, key="files", onUpload=null) {
        // Append files to a FormData in order to be sent over HTTP
        const formData = new FormData();
        for (let i = 0 ; i < files.length ; i++) {
            formData.append(key, files[i], files[i].name);
        }
        // Perform the upload
        const call = callApi("POST", uploadEndpoint, {body: formData});
        if (call) {
            setIsUploading(true);
            call.then(response => {
                if (response.status !== 200) {
                    console.error(
                        `Failed to upload: ${response.status} ${response.statusText} \n` +
                        `files:${files.map((f) => ` ${f.name}`).toString()} \n` +
                        `to (POST) ${uploadEndpoint}`
                    );
                } else {
                    onUpload && onUpload();
                }
            })
            .finally((_response) => setIsUploading(false));
        } else {
            setIsUploading(false);
        }
    }

    return {
        "isUploading": isUploading,
        "upload": useCallback(upload, [callApi])
    };
}

export function useFileUpload(uploadEndpoint, formDataKey="files") {
    const {isUploading, upload} = useGeneralFileUpload();
    return {
        isUploading,
        "upload": (files, onUpload=null) => upload(uploadEndpoint, files, formDataKey, onUpload)
    }
}