import { getFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";


export function getCurrentTabs() {
    return getFromLocalStorage(StorageKeys.ASSESSMENT_TABS, {});
}

export function getCurrentTabById(assessmentId) {
    return getCurrentTabs()[assessmentId];
}

export function setCurrentTabById(assessmentId, currentTab) {
    const newCurrentTabs = getCurrentTabs();
    newCurrentTabs[assessmentId] = currentTab;
    return saveToLocalStorage(StorageKeys.ASSESSMENT_TABS, newCurrentTabs);
}