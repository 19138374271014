import Loading from "src/components/ui/Loading";
import { ExamStructure } from "src/pages/goodpoint/assessment/components/structure/ExamStructure";
import "./AnswersStructure.css"
import AnswerItem from "src/pages/goodpoint/assessment/tabs/answers/structure/AnswerItem";


export default function AnswersStructure({ selectedStudent, structureMap }) {
    function AnswersHeader({ structureMap }) {
        const numQuestions = Object.keys(structureMap).length;
        return (
            <div className="flex-row align-center justify-content-space-between">
                <div>
                    <b>{selectedStudent.name}</b>
                    <p>{`ID ${selectedStudent.student_id}`}</p>
                </div>
                <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""} Answered</p>
            </div>
        )
    }

    if (!structureMap) {
        return <Loading />
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                structureMap={structureMap}
                HeaderComponent={AnswersHeader}
                ItemComponent={AnswerItem}
            />
        </div>
    )
}