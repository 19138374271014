export const TermsOfUse = `

# Zanista AI LTD Terms and Conditions

This policy is effective as of 7 June 2024

## Introduction

Please read these terms and conditions ("terms and conditions", "terms of use", "terms") carefully before using the website and services (“website", "services") operated by Zanista AI LTD ("us", 'we", "our", “Zanista AI”). These terms apply to all visitors, users, and others who access or use the services.

## Conditions of use

By accessing this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. Zanista AI only grants use and access of this website, its products, and its services to those who have accepted its terms.

You agree to use the website only for lawful purposes. You agree to not take any action that may result in rendering the website inaccessible to others. You agree not to add to, subtract from or modify the content of the website in any way that is not intended for you. You are responsible for ensuring that all persons who access our services through your internet connection are aware of these terms and comply with them.

## Privacy policy

Before you continue using our website, we advise you to read our privacy policy at [https://zanista.ai/legal?tab=privacy](https://zanista.ai/legal?tab=privacy) regarding our user data collection. It will help you better understand our practices.

Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information.

## Disclaimer

The materials on Zanista AI's website are provided on an 'as is' basis. Zanista AI makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.

Further, Zanista AI does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.

## Accuracy of materials

The materials appearing on Zanista AI's website could include technical, typographical, or photographic errors. Zanista AI does not warrant that any of the materials on its website are accurate, complete or current. Zanista AI may make changes to the materials contained on its website at any time without notice. However, Zanista AI does not make any commitment to update the materials.

## Links

Zanista AI has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Zanista AI of the site. Use of any such linked website is at the user's own risk.

## Downloading Files

Zanista AI cannot and does not guarantee or warrant that files available for downloading through our services and website will be free of infection by software viruses or other harmful code, files or programs.

## Age restriction

You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age, and you may legally adhere to this Agreement. Zanista AI assumes no responsibility for liabilities related to age misrepresentation.

## Intellectual property

You agree that all materials, products, and services provided on this website are the property of Zanista AI, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Zanista AI’s intellectual property in any way, including electronic, digital, or new trademark registrations.

## User-Generated Content

By uploading, publishing, or otherwise making available any content on or through our services, you hereby grant us a non-exclusive, worldwide, royalty-free, perpetual, and transferable license to use, reproduce, modify, distribute, prepare derivative works of, display, and perform the content in connection with the service and our business, including without limitation for promoting and redistributing part or all of the service in any media formats and through any media channels. You also hereby grant each user of the service a non-exclusive license to access your content through the service, and to use, reproduce, distribute, display, and perform such content as permitted through the functionality of the service and under these terms.

For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.

## User accounts

As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.

If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly.

You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.

We reserve all rights to edit or remove content and cancel orders at our sole discretion.

## Termination

We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.

## Applicable law

By using this website, you agree that the laws of United Kingdom, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Zanista AI and you, or its business partners and associates. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.

## Disputes

Any dispute related in any way to your use of this website or to products you purchase from us shall be arbitrated by state or federal court and you consent to exclusive jurisdiction and venue of such courts.

## Indemnification

You agree to indemnify Zanista AI and its affiliates and hold Zanista AI harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.

## Limitation on liability

In no event shall Zanista AI, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential purpose.

Zanista AI reserves the right to edit, modify, and change this Agreement at any time. This Agreement is an understanding between Zanista AI and the user, and this supersedes and replaces all prior agreements regarding the use of this website. By using this website, you are agreeing to be bound by the then current version of these terms of service.

## Contact information

If you have any comments, questions or concerns about this document, please contact us at [info@zanista.ai](mailto:info@zanista.ai) or write to us at:

Zanista AI LTD

Flat 116 Falconbrook Gardens 17 Silvertown Way

London

England

E16 1PG
`
