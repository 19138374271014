import dayjs from "dayjs";
import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";

export default function useSendConfig(
    examId,
    updateAssessmentObject
) {
    const callApi = useCallApi({
        msalRequest: {scopes: protectedResources.apiGoodPoint.scopes.write}
    });

    const sendConfig = useCallback((configUpdates) => {
        const postBody = {
            "exam_config": {
                ...configUpdates,
                "last_modified": dayjs().unix()
            }
        };
        return callApi("POST", GoodPointApi.Assessment.Config(examId), {body: postBody})
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(r => {
                        updateAssessmentObject({
                            configure_step: r["configure_step"]
                        });
                    });
                } else {
                    console.error(`Failed to post config: (${response.status}) ${response.statusText}`);
                }
            });
    }, [callApi, examId, updateAssessmentObject]);

    return sendConfig;
}