import './Divider.css'

export default function Divider({
    lineColour="lightgray", 
    shadowColour="rgba(0, 0, 0, 0.25)",
    margin="15px 0px 5px 0px"
}) {
    return (
        <div className="divider" style={{"margin": margin}}>
            <hr style={{
                color: shadowColour,
                borderBottom: `1px solid ${lineColour}`
            }}/>
        </div>
    )
}