import './BackLink.css'
import { FastArrowLeft } from 'iconoir-react';
import { Link } from 'react-router-dom';

export default function BackLink({ prevPage, href="/" }) {
    return (
        <div className='back-link'>
            <Link to={href}>
                <FastArrowLeft width={20} height={20}/>
                <p>Back to {prevPage}</p>
            </Link>
        </div>
    )
}
