import "./LogProgress.css"

import { ThreeCircles } from "react-loader-spinner"
import ProgressBar from "./ProgressBar"
import { useContext, useEffect, useState } from "react"
import { LogUpdateContext } from "src/pages/goodpoint/assessment/LogUpdateContext";

export default function LogProgress({ text, Spinner=ThreeCircles }) {
    const { logUpdate } = useContext(LogUpdateContext);

    const [progress, setProgress] = useState(0.0)
    const [message, setMessage] = useState("Connecting...")

    useEffect(() => {
        if (!logUpdate) return;
        const last_log = logUpdate
            .map((log, _index) => {
                const [progress, progress_total] = (log.progress === "NULL" ? [0, 1] : log.progress.split("/"))
                return {
                    type: log.type,
                    message: log.message,
                    "progress": parseFloat(progress),
                    "progress_total": parseFloat(progress_total),
                    "progress_null": log.progress === "NULL"
                }
            })
            .reduce((log1, log2) => {
                return (log1.progress > log2.progress) ? log1 : log2
            }, {
                progress: -1,
                progress_total: 1,
                message: "Connecting..."
            })
        setMessage(`${last_log.progress > 0 ? `${Math.max(last_log.progress, 0)}/${last_log.progress_total}: ` : ""}${last_log.message}`)
        setProgress(Math.max(last_log.progress, 0.0) / last_log.progress_total)
    }, [logUpdate, setProgress])

    return <div className="loading-container">
        <div className="loading">
            <Spinner
                visible={true}
                height="200"
                width="200"
                color="#F49569"
            />
            <b>{text}</b>
            <ProgressBar progress={progress}/>
            <p className="log">{message}</p>
        </div>
    </div>
}