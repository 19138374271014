import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FocusWithin } from "@component-driven/react-focus-within";

import "./GradeBoundaries.css";
import { Check, PlusCircle, XmarkCircle } from "iconoir-react";
import IconButton from "src/components/ui/IconButton";


// const Presets = Object.freeze({
//     "A+ to F": [
//         ["A+", 90], ["A", 80], ["B", 70], ["C", 60], ["D", 50], ["E", 40], ["F", 0]
//     ],
//     "Pass/Fail": [
//         ["Pass", 50], ["Fail", 0]
//     ]
// })

function BoundaryRow({ index, gradeBoundariesList, setGradeBoundariesList }) {
    const grade = gradeBoundariesList[index][0];
    const mark = gradeBoundariesList[index][1];

    const gradeInput = useRef(null);
    const markInput = useRef(null);

    function onChangeGrade(event) {
        const newGrade = event.target.value;
        const result = [...gradeBoundariesList];
        result[index] = [newGrade, mark];
        setGradeBoundariesList(result);
    }

    function onChangeMark(event) {
        const newMark = parseInt((event.target.value ?? `${mark}`).replace(/\D/g, ""));
        if (newMark <= 100) {
            const result = [...gradeBoundariesList];
            result[index] = [grade, newMark];
            setGradeBoundariesList(result);
        }
    }

    function deleteSelf() {
        const result = [...gradeBoundariesList];
        result.splice(index, 1);
        setGradeBoundariesList(result);
    }

    useEffect(() => {
        if ((grade === undefined) && gradeInput) {
            gradeInput.current.focus();
        }
    }, [grade, gradeInput]);

    const enableDelete = gradeBoundariesList.filter(([g, m]) => (g !== undefined) && (m !== undefined)).length > 1;

    const badGrade = useMemo(() => {
        const result = gradeBoundariesList.findIndex(([g, _m]) => g === grade) !== index;
        return result;
    }, [grade, gradeBoundariesList, index]);

    const badMark = useMemo(() => {
        const result = gradeBoundariesList.findIndex(([_g, m]) => m === mark) !== index;
        return result;
    }, [gradeBoundariesList, index, mark]);

    return (
        <tr className="boundary-row align-center justify-content-center">
            <td 
                className={`input-cell cursor-text ${badGrade ? "bg-orange-mid" : "null"}`} 
                onClick={() => { gradeInput.current.focus(); }}
            >
                <input 
                    ref={gradeInput}
                    className="grade-input border-none margin-none outline-none"
                    type="text" 
                    value={grade} 
                    placeholder="Grade"
                    onChange={onChangeGrade}
                />
            </td>
            <td 
                className={`input-cell flex-row align-center cursor-text fill-width ${badMark ? "bg-orange-mid" : "null"}`} 
                onClick={() => { markInput.current.focus(); }}
            >
                <input
                    ref={markInput}
                    className="mark-input border-none margin-none outline-none"
                    type="text" 
                    value={mark}
                    placeholder="Mark"
                    onChange={onChangeMark}
                />
                {
                    (mark !== undefined) && <p>%</p>
                }
            </td>
            <td className="align-center justify-content-center">
                {
                    <button 
                        className={`delete-grade-row padding-none margin-none align-center`}
                        onClick={deleteSelf}
                        disabled={!enableDelete}
                    >
                        <XmarkCircle height={"1.3em"} style={{opacity: enableDelete ? 1 : 0}}/>
                    </button>
                }
            </td>
        </tr>
    )
}

export default function GradeBoundaries({ updateExamConfig }) {
    const {assessmentObject} = useContext(AssessmentContext);

    const [gradeBoundariesList, setGradeBoundariesList]
        = useState(Object.entries(assessmentObject.exam_config.grade_thresholds).sort(([_g1, m1], [_g2, m2]) => m2 - m1));

    const [toUpdate, setToUpdate] = useState(false);

    function saveGradeBoundaries() {
        var gb = gradeBoundariesList.filter(([g, m]) => (g !== undefined) && (m !== undefined));
        gb = Array.from(new Set(gb.map(([_g, m]) => m)));
        gb = gb.map((mF) => gradeBoundariesList.find(([_g, m]) => m === mF));
        if (gb.find(([_g, m]) => m === 0) === undefined) {
            gb.push(["Fail", 0]);
        }
        const gbObj = Object.fromEntries(gb);
        updateExamConfig({grade_thresholds: gbObj});
        setGradeBoundariesList(Object.entries(gbObj).sort(([_g1, m1], [_g2, m2]) => m2 - m1));

        setToUpdate(false);
    }

    function setGradeBoundariesListSorted(newGb) {
        setGradeBoundariesList(newGb);
        setToUpdate(true);
    }

    function addRow() {
        if (gradeBoundariesList.filter(([g, m]) => (g === undefined) || (m === undefined)).length === 0) {
            setGradeBoundariesList([...gradeBoundariesList, [undefined, undefined]]);
        }
    }

    function onBlur() {
        setGradeBoundariesList(gb => gb.filter(([g, m]) => (g !== undefined) && (m !== undefined)));
    }
    
    return (
        <div className="flex-row gap-large">
            <FocusWithin onBlur={onBlur}>
                <table className='grade-grid padding-small rounded-xsmall'>
                    <thead>
                        <tr className="padding-large">
                            <td><p className="margin-none text-align-center">Grade</p></td>
                            <td><p className="margin-none text-align-center">Min. Mark (%)</p></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gradeBoundariesList
                                .map((_, index) => (
                                    <BoundaryRow 
                                        key={index} 
                                        index={index}
                                        gradeBoundariesList={gradeBoundariesList}
                                        setGradeBoundariesList={setGradeBoundariesListSorted}
                                    />
                                ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2}>
                                <div className="flex-row gap-small fill-width">
                                <button className="add-grade flex-row gap-small align-center fill-width" onClick={addRow}>
                                    <PlusCircle height={"1.3em"}/>Add grade
                                </button>
                                {
                                    toUpdate && <IconButton
                                        Icon={Check}
                                        iconSize={"1.3em"}
                                        text={"Save"}
                                        borderSize="var(--border-thin)"
                                        roundedSize="var(--rounded-xsmall)"
                                        bgColour="var(--orange-light)"
                                        onClick={saveGradeBoundaries}
                                    />
                                }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </FocusWithin>
        </div>
    )
}