import { useContext, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { GradingImages } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import Title from "src/components/content/Title";
import Divider from "src/components/layout/Divider";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import StudentOverallFeedback from "src/pages/goodpoint/assessment/tabs/grading/student-performance/StudentOverallFeedback";
import { calculatePercentage } from "src/util/StringUtil";


export default function StudentStats({ examResults }) {
    const {assessmentObject} = useContext(AssessmentContext);
    const {selectedStudent} = useContext(StudentsContext);
    const callApi = useCallApi();

    const [studentQuantileImgSrc, setStudentQuantileImg] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);

    useEffect(() => {
        if (!selectedStudent) return;
        callApi("GET", GoodPointApi.Grading.Images(assessmentObject.id, GradingImages.QUANTILE_PLOT, { student_id: selectedStudent.student_id }))?.then(response => {
            if (response.status === 200) {
                response.blob().then(img => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setStudentQuantileImg(reader.result)
                    }
                    reader.readAsDataURL(img)
                })
            }
        });
    }, [callApi, assessmentObject, studentQuantileImgSrc, selectedStudent]);

    return (
        <div className="student-stats">
            <Title
                Size="b"
                title={selectedStudent?.name ?? "Student"}
                subtitle={`ID ${selectedStudent?.student_id}`} />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
            { /* TODO: Percentage using division can be wrong due to js FP precision */}
            <p>
                <b>Total marks: </b>
                {
                    `${selectedStudent?.total_marks}/${examResults?.total_mark} | ` + 
                    `${selectedStudent && examResults && calculatePercentage(selectedStudent.total_marks, examResults.total_mark)}%`
                }
            </p>
            <p><b>Grade: </b>{selectedStudent?.grade}</p>
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
            <StudentOverallFeedback />
            <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)'/>
            {
                <img className="cursor-pointer" src={studentQuantileImgSrc} alt="Student Quantile" onClick={() => { setPopupOpen(true); }}/>
            }

            <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
                <div className='bg-white rounded-small'>
                    <img src={studentQuantileImgSrc} alt="Student Quantile" />
                </div>
            </Popup>
        </div>
    )
}