import { FastArrowDown, FastArrowUp } from "iconoir-react";
import { useContext } from "react";
import { StudentsMenu } from "src/pages/goodpoint/assessment/components/StudentsMenu";
import { StudentsContext } from "src/pages/goodpoint/assessment/tabs/answers/StudentsContext";
import { calculatePercentage } from "src/util/StringUtil";


function GradingStudentButton({ studentData, examResults }) {
    const {selectedStudent, setSelectedStudent} = useContext(StudentsContext);
    const isSelected = selectedStudent?.student_id === studentData.student_id;
    return (
        <button 
            className={
                `graded-student-button fill-width clickable border-thin rounded-small padding-small outline-thin ` + 
                `${isSelected ? "bg-orange-mid outline-black" : "bg-yellow-light outline-transparent"}`
            } 
            style={{padding: "5px 10px"}}
            onClick={() => setSelectedStudent(studentData)}
        >
            <div className={`graded-student-button-grid fill-width justify-content-left`}>
                <p className="graded-student-name justify-self-left"><b>{studentData.name ?? studentData.student_id}</b></p>
                <p className="graded-student-id justify-self-left">{studentData.name && studentData.student_id}</p>
                <p className="graded-student-marks justify-self-right">
                    {studentData.total_marks}/{examResults?.total_mark} Marks
                </p>
                <p className="graded-student-percentage justify-self-right">
                    ({calculatePercentage(studentData.total_marks, examResults?.total_mark)}%)
                </p>
                <p className="graded-student-grade justify-self-right">
                    Grade {studentData.grade}
                </p>
            </div>
        </button>
    )
}

export default function GradingStudentsList({ 
    examResults, orderBy, setOrderBy, isAscendingOrder, setIsAscendingOrder
}) {
    return (
        <div className="grading-students-list flex-col gap-mid fill-width">
            <div className="flex-row justify-content-space-between align-center">
                <h2>Students</h2>
                <div className="feedback-order-by flex-row align-center margin-small">
                    <p>Order by:</p>
                    <select className="order-by-select padding-x-small font-size-large margin-small" value={orderBy} onChange={(event) => setOrderBy(event.target.value)}>
                        <option value="grade">Grade</option>
                        <option value="name">Name</option>
                    </select>
                    <div className="cursor-pointer" onClick={() => setIsAscendingOrder(!isAscendingOrder)}>
                        {
                            isAscendingOrder ? <FastArrowUp color="var(--orange-dark)" /> : <FastArrowDown color="var(--orange-dark)" />
                        }
                    </div>
                </div>
            </div>
            <StudentsMenu extraProps={{examResults}} StudentButtonComponent={GradingStudentButton}/>
        </div>
    )
}