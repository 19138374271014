import Latex from "react-latex-next";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";
import { calculatePercentage } from "src/util/StringUtil";
import "./StudentFeedbackItem.css"
import { StructureItem } from "src/pages/goodpoint/assessment/components/structure/StructureItem";


function StudentFeedbackItemHeader({ itemData, nestingLevel }) {
    return (
        <div className="student-feedback-item-header fill-width">
            <p className="student-feedback-item-question-tag justify-self-left">
                <b>{(nestingLevel > 0) ? "Part " : ""}{itemData.tag}</b>
            </p>
            <p className="student-feedback-item-flagged justify-self-right" style={{color: itemData.flagged ? "black" : "transparent"}}>
                (NEEDS REVIEW)
            </p>
            <p className="student-feedback-item-marks">{itemData.marks}/{itemData.max_marks} Marks</p>
            <p className="student-feedback-item-percentage">{calculatePercentage(itemData.marks, itemData.max_marks)}%</p>
        </div>
    );
}

function StudentFeedbackItemContent({ itemData }) {
    return <div className="feedback-content">
        {
            itemData.feedback &&
                <StructureSection heading={"Feedback"} aiGenerated>
                    <Latex>{itemData.feedback}</Latex>
                </StructureSection>
        }
    </div>
}

export default function StudentFeedbackItem({ itemData, isFilterOnlyFlagged, isFilterOnlyMistakes, ...props }) {
    if (isFilterOnlyMistakes && !(itemData.marks < itemData.max_marks)) {
        return null;
    }
    if (isFilterOnlyFlagged && !itemData.flagged) {
        return null;
    }

    return <StructureItem 
        itemData={itemData}
        HeaderComponent={StudentFeedbackItemHeader} 
        ContentComponent={StudentFeedbackItemContent} 
        {...props}
    />;
}