import { Download } from "iconoir-react";
import { TailSpin } from "react-loader-spinner";
import { useBlobDownload } from "src/api/useBlobDownload";
import IconButton from "src/components/ui/IconButton";

export default function DownloadButton({ 
    downloadEndpoint, 
    filename,
    text,
    downloadingText=`Downloading...`,
    Icon=Download,
    DownloadingIcon=TailSpin,
    ...props
}) {
    const {download: downloadRubric, isDownloading} = useBlobDownload(downloadEndpoint, filename);

    return (
        <IconButton
            Icon={isDownloading ? DownloadingIcon : Icon}
            text={isDownloading ? downloadingText : text}
            onClick={downloadRubric}
            clickable={!isDownloading}
            {...props}
        />
    )
}