import { useCallback, useState } from "react";

export default function useExpandCollapse(initiallyExpanded=false) {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
    const [isCollapsed, setIsCollapsed] = useState(!initiallyExpanded);

    const expand = useCallback(() => { 
        setIsExpanded(true); setIsCollapsed(false);
    }, []);

    const collapse = useCallback(() => { 
        setIsExpanded(false); setIsCollapsed(true);
    }, []);

    const toggle = useCallback(() => {
        setIsExpanded((expanded) => !expanded);
        setIsCollapsed((collapsed) => !collapsed);
    }, [])

    const reset = useCallback(() => { 
        setIsExpanded(false); setIsCollapsed(false);
    }, []);

    return {
        isExpanded, isCollapsed,
        setIsExpanded, setIsCollapsed,
        expand, collapse, 
        toggle, reset
    }
}