import { ArrowUp } from "iconoir-react";
import { useState } from "react";

import "./ScrollToTopButton.css"

export default function ScrollToTopButton({ scrollThreshold=250 }) {
    const [visible, setVisible] = useState(false);

    function toggleVisible() {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > scrollThreshold) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }

    window.addEventListener("scroll", toggleVisible);

    return (
        <button className={`scroll-to-top-button ${!visible ? "display-none" : ""} border-mid clickable bg-orange-light`} onClick={scrollToTop}>
            <ArrowUp width={20}/>
        </button>
    )
}