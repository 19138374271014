import { BadgeCheck, InfoCircle, NavArrowDown, NavArrowRight, PageStar, WarningTriangle } from "iconoir-react";
import { useContext, useEffect, useState } from "react";
import Latex from "react-latex-next";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import IconButton from "src/components/ui/IconButton";
import StructureSection from "src/pages/goodpoint/assessment/components/structure/StructureSection";

import "./SolutionSteps.css";

const StepTabText = {
    SOLUTION: "Solution",
    EXPECTATION: "Expectation",
    COMMON_ERRORS: "Common Errors",
    MARKING: "Marking"
}

const StepTabIcon = {
    SOLUTION: BadgeCheck,
    EXPECTATION: InfoCircle,
    COMMON_ERRORS: WarningTriangle,
    MARKING: PageStar
}

function StepTabs({ stepTab, setStepTab }) {
    return <div className="step-tabs flex-col gap-mid">
        {
            Object.entries(StepTabText).map(([k, v], i) => {
                const selected = (stepTab === v);
                return <IconButton
                    key={i}
                    Icon={StepTabIcon[k]}
                    iconColour={selected ? "black" : "var(--orange-dark)"}
                    bgColour={selected ? "var(--orange-mid)" : "var(--yellow-light)"}
                    borderSize={"var(--border-thin)"}
                    borderColour={selected ? "black" : "transparent"}
                    roundedSize="var(--rounded-xsmall)"
                    paddingSize="2px 5px"
                    onClick={() => setStepTab(v)}
                    justifyContent="left"
                    text={v}
                    className={"justify-content-left"}
                />
            })
        }
    </div>
}

function Step({ index, step }) {
    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetAll
    } = useContext(ExpandCollapseContext);

    const {isExpanded, expand, collapse, toggle} = useExpandCollapse();
    
    useEffect(() => { isAllExpanded && expand(); },    [isAllExpanded, expand]);
    useEffect(() => { isAllCollapsed && collapse(); }, [isAllCollapsed, collapse]);

    const [stepTab, setStepTab] = useState(StepTabText.SOLUTION);

    var stepContent = null
    switch (stepTab) {
        case StepTabText.SOLUTION:
            stepContent = <div>
                <b>Solution: </b>
                <Latex>{step.solution}</Latex>
            </div>
            break;
        case StepTabText.EXPECTATION:
            stepContent = <div>
                <b>Expanded Solution: </b>
                <Latex>{step.expanded_solution}</Latex>
                <p></p><br></br>
                <b>Student Expectation: </b>
                <Latex>{step.expected}</Latex>
            </div>
            break;
        case StepTabText.COMMON_ERRORS:
            stepContent = <div>
                <b>Common Errors: </b>
                <Latex>{step.common_errors}</Latex>
                <p></p><br></br>
                <b>Examples: </b>
                <ul> {
                    ([].concat(step.common_errors_examples)).map((v, i) => {
                        return <li key={i}><Latex>{v}</Latex></li>
                    })
                } </ul>
            </div>
            break;
        case StepTabText.MARKING:
            stepContent = <div>
                <b>Marks for this step: </b>
                <p className="fg-orange-dark">{step.step_mark} Marks</p>
                <p></p><br></br>
                <b>Mark deductions for errors: </b>
                <p className="fg-orange-dark">{step.step_mark_deductions} Marks</p>
            </div>
            break;
        default:
            break;
    }

    return <div className="step flex-col gap-small">
        <div 
            className="step-heading flex-row gap-mid justify-content-space-between fill-width cursor-pointer align-center" 
            onClick={() => { toggle(); resetAll(); }}
        >
            <div className="flex-row gap-mid text-nowrap">
                {
                    isExpanded 
                        ? <NavArrowDown color="var(--orange-dark)" width={"1em"}/> 
                        : <NavArrowRight color="var(--orange-dark)" width={"1em"}/>
                }
                <p className="fg-black">Step {index}</p>
                <div className="step-heading-text fg-orange-dark">
                    <Latex>{step.step}</Latex>
                </div>
            </div>
            <div className="step-divider"></div>
            <p className="step-heading-marks text-nowrap fg-orange-dark">{step.step_mark} Marks</p>
        </div>
        {
            isExpanded && <div className="step-body flex-row gap-large">
                <StepTabs stepTab={stepTab} setStepTab={setStepTab} />
                <div className="step-content font-size-medium">
                    {stepContent}
                </div>
            </div>
        }
    </div>
}

export default function SolutionSteps({ steps }) {
    const expandCollapse = useExpandCollapse(false);

    if (!steps) {
        return null
    }

    return <ExpandCollapseContext.Provider value={expandCollapse}>
        <StructureSection 
            heading={"Step-by-Step"} 
            headingControls={
                <ExpandCollapseButtons
                    props={{
                        iconSize: 17, 
                        borderSize: "var(--border-thin)", 
                        paddingSize: "0px 5px",
                        roundedSize: "var(--rounded-xsmall)"
                    }}
                />
            }
            aiGenerated
        >
            {
                <div className="steps-list flex-col gap-mid">
                    { steps.map((s, i) => <Step key={i} step={s} index={i + 1}/>) }
                </div>
            }
        </StructureSection>
    </ExpandCollapseContext.Provider>
}
