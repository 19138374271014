import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, b2cPolicies } from 'src/auth/AuthConfig';
import { clarity } from 'react-microsoft-clarity';

const MsalInstance = new PublicClientApplication(msalConfig);
//let msalInit;
//MsalInstance.initialize().then(val => msalInit = val);
MsalInstance.initialize();

// Default to using the first account if no account is active on page load
if (!MsalInstance.getActiveAccount() && MsalInstance.getAllAccounts().length > 0) {
    MsalInstance.setActiveAccount(MsalInstance.getAllAccounts()[0]);
}

if (process.env.NODE_ENV === 'production') clarity.init('mnsngxfxkv');

MsalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        MsalInstance.setActiveAccount(event.payload.account);
        let currentAccount = MsalInstance.getActiveAccount();
        const USER_ID = currentAccount.idTokenClaims['extension_UserID']
        if (USER_ID && clarity.hasStarted()) {
            clarity.identify(USER_ID, {});
        }
    }

    if (event.error) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error.errorMessage.includes('AADB2C90118')) {
            console.log('Forgot password error');
            const resetPasswordRequest = {
                authority: b2cPolicies.authorities.forgotPassword.authority,
                scopes: [],
            };
            MsalInstance.loginRedirect(resetPasswordRequest);
        }
        if (event.error.errorMessage.includes('AADB2C90091')) {
            console.log('Cancelled signup error');
            const signUpSignInRequest = {
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                scopes: [],
            };
            MsalInstance.loginRedirect(signUpSignInRequest);
        }
        if (event.error.errorMessage.includes('AADB2C90006')) {
            console.log('Sign out redirect error');
            const signUpSignInRequest = {
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                scopes: [],
            };
            MsalInstance.loginRedirect(signUpSignInRequest);
        }
        if (event.error.errorMessage.includes('AADB2C90077')) {
            console.log("No existing session error");
            const signUpSignInRequest = {
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                scopes: [],
            };
            MsalInstance.loginRedirect(signUpSignInRequest);
        }
    }
});

export default MsalInstance;