import { Link } from 'react-router-dom';
import Footer from 'src/pages/dashboard/Footer';
import logo from 'src/assets/logo.png'

function LoadingSignIn() {

    return (
        <div className='coming-soon-screen centered'>
            <Link to="/">
                <img src={logo} alt="Zanista AI" />
            </Link>
            <p>
                You are being redirecting to login.
            </p>
            <a href={window.location.href} className='text-xs text-gray-400 pt-4 hover:underline'>
                Click here if you are not redirected
            </a>
        <Footer />
        </div>
    );
}

export default LoadingSignIn;
