import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import { useEffect, useState, useCallback } from 'react';
import { Trash } from 'iconoir-react';
import useNewsWitchApi from 'src/api/newswitch/useNewsWitchApi';

const keys = ["ticker", "name", "type", "source", "asset_class", "market_cap", "exchange", "exchange:ticker", "change"]
// Keys that will not be shown in the table
const excludedKeys = ["type", "market_cap", "change", "exchange:ticker"]

function TickerInputs() {
    return  (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="NewsWitch" href=".." />
                <Title 
                    title="Change Daily Tickers"/>
                <TickerInputTable />
            </div>
        </div>
    )
}

function TickerInputTable({ publicMode = undefined }) {
    // Once isLoading is false, we can call the API using callAPI
    const callAPI = useNewsWitchApi();

    const [inputs, setInputs] = useState(undefined);
    const [originalInputs, setOriginalInputs] = useState(); // Inputs we got from api call
    const [edited, setEdited] = useState(false);
    const [getTickerInfo, setGetTickerInfo] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);

    // Fetch the inputs from the API
    useEffect(() => {
        const endpoint = publicMode ? "/public/input" : "/input";
        callAPI("GET", endpoint)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const inputs = Object.values(body["input"])
                    setInputs(inputs);
                    // Ensure we use a dep copy of inputs
                    setOriginalInputs(JSON.parse(JSON.stringify(inputs)));
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI, publicMode]);

    // Save the changes to the API
    useEffect(() => {
        if (!savingChanges || !edited) return;
        setSavingChanges(false);
        // Convert the list into a dictionary and filter empty
        const inputMap = {};
        inputs.forEach(i => {
            if (i['ticker'] === "") return;
            inputMap[i['ticker']] = i;
        });
        const body = {
            input: inputMap
        }
        const endpoint = publicMode ? "/public/input" : "/input";
        callAPI("POST", endpoint, { body: body })?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    setOriginalInputs(JSON.parse(JSON.stringify(inputs)));
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI, savingChanges, inputs, edited, publicMode]);

    // Set edited flag if inputs have changed since fetch
    useEffect(() => {
        if (inputs === undefined || originalInputs === undefined) return;
        // Remove empty tickers
        const filteredInputs = inputs.filter(i => i['ticker'] !== "");
        if (filteredInputs.length !== originalInputs.length) {
            setEdited(true);
            return;
        }
        for (let i = 0; i < filteredInputs.length; i++) {
            for (let key of keys) {
                if (filteredInputs[i][key] !== originalInputs[i][key]) {
                    setEdited(true);
                    return;
                }
            }
        }
        setEdited(false);
    }, [originalInputs, inputs]);

    // Fetch the inputs from the API
    useEffect(() => {
        if (!getTickerInfo || inputs === undefined) return;
        setGetTickerInfo(false);
        inputs.forEach(input => {
            if (input['source'] === undefined) {
                callAPI("GET", "/ticker?ticker=" + input["ticker"])?.then(response => {
                    if (response.status === 200) {
                        response.json().then(body => {
                            const tickerInfo = body['ticker'];
                            setInputs(prev => prev.map(i => {
                                if (i['ticker'] === tickerInfo['ticker']) {
                                    i = {...tickerInfo};
                                }
                                return i;
                            }));
                            setOriginalInputs(JSON.parse(JSON.stringify(inputs)));
                        });
                    } else {
                        console.error(response);
                    }
                });
            }
        });
    }, [callAPI, getTickerInfo, inputs]);

    const createNewTicker = () => {
        // First check if there is already an empty ticker
        for (let i = 0; i < inputs.length; i++) {
            let isEmpty = true;
            for (let key of keys) {
                if (inputs[i][key] !== "") {
                    isEmpty = false;
                    break;
                }
            }
            if (isEmpty) return;
        }
        const ticker = {};
        keys.forEach(key => {
            ticker[key] = "";
        });
        setInputs([...inputs, ticker]);
    }

    const addTicker = (ticker) => {
        console.log("Adding ticker", ticker);
        // Check if the ticker is already in the list
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i]['ticker'] === ticker['ticker']) {
                console.log("Ticker already in list");
                return;
            }
        }
        // Fetch the ticker info from api
        setGetTickerInfo(true);
        setInputs([...inputs, ticker]);
    }

    return (
        <div className='flex-col gap-4'>
            <div className="flex-row gap-x-2 justify-end items-center">
                {
                    edited &&
                    <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-100 hover:bg-orange-300"
                        onClick={() => setSavingChanges(true)}
                    >
                        Save Changes
                    </div>
                }
                {
                    (edited || inputs?.filter(i => i['ticker'] !== "").length !== inputs?.length) &&
                    <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-100 hover:bg-orange-300"
                        onClick={() => setInputs(JSON.parse(JSON.stringify(originalInputs)))}
                    >
                        Reset
                    </div>
                }
                <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-300 hover:bg-orange-400"
                    onClick={createNewTicker}
                >
                    Add new ticker
                </div>
                <SearchBar onSelect={addTicker}/>
            </div>
            <div className='p-2 rounded outline outline-1'>
                {
                    inputs === undefined ? <div>Loading inputs...</div> : ( 
                        inputs.length === 0 ? <div>No tickers found</div> :
                        <div>
                            <div className='flex-row items-end'>
                            <p className='w-8 text-xs invisible'>0</p>
                            <div className='w-full grid grid-cols-5'>
                                {
                                    keys.filter(key => !excludedKeys.includes(key)).map(val => {
                                        if (val.includes("exchange:ticker")) {
                                            val = "exch:ticker";
                                        }
                                        return <p className='text-xs'>{val.toUpperCase()}</p>
                                    })
                                }
                            </div>
                                <div className='cursor-pointer rounded bg-red-500 outline outline-1 w-4 h-4 mx-2 invisible'>
                                </div>
                            </div>
                            {
                                inputs.map((input, index) => {
                                    return (
                                        <div className='flex-row items-end'>
                                            <p className='w-8 text-xs text-gray-600 pb-2'>{index + 1}</p>
                                            <div key={input['ticker']} className='border-t pt-2 my-2 w-full grid grid-cols-5'>
                                                {   
                                                    keys.filter(key => !excludedKeys.includes(key)).map(key => {
                                                        return <input value={input[key]} type="text" className={'text-xs ' + (key === 'ticker' && 'font-bold')}
                                                            onChange={(e) => {
                                                                const newInputs = inputs.map(i => {
                                                                    if (i['ticker'] === input['ticker']) {
                                                                        i[key] = e.target.value;
                                                                    }
                                                                    return i;
                                                                });
                                                                setInputs(newInputs);
                                                            }}
                                                        />
                                                    })
                                                }
                                            </div>
                                            <div className='cursor-pointer rounded bg-red-500 outline outline-1 w-4 h-4 m-2'
                                                onClick={() => {
                                                    const newInputs = inputs.filter(i => i['ticker'] !== input['ticker']);
                                                    setInputs(newInputs);
                                                }}
                                            >
                                                <Trash color='white' height="20" width="16" className='m-auto'/>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

function SearchBar({ onSelect }) {
    const callAPI = useNewsWitchApi();

    const [query, setQuery] = useState("");
    const [tickers, setTickers] = useState();
    const [filteredTickers, setFilteredTickers] = useState();
    const [isFocused, setIsFocused] = useState(false);

    // Fetch the tickers from the API
    useEffect(() => {
        callAPI("GET", "/tickers")?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    setTickers([...new Set(Object.values(body["tickers"]))]);
                    setFilteredTickers(JSON.parse(JSON.stringify(Object.values(body["tickers"]))));
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI]);

    // Filter tickers based on the query
    const filterTickers = useCallback((value) => {
        if (value === "") {
            setFilteredTickers(tickers);
            return;
        }
        // Filter by ticker
        // First get all tickers/names starting with the query
        const closestMatches = tickers.filter(ticker => ticker["ticker"].toLowerCase().startsWith(value.toLowerCase()));
        const nameClosestMatches = tickers.filter(ticker => ticker["name"].toLowerCase().startsWith(value.toLowerCase()));
        // Then get the tickers/names with the query anywhere in the ticker
        const nameAnywhereMatches = tickers.filter(ticker => ticker["name"].toLowerCase().includes(value.toLowerCase()));
        const anywhereMatches = tickers.filter(ticker => ticker["ticker"].toLowerCase().includes(value.toLowerCase()));
        // Combine the lists and remove duplicates
        const matches = [...new Set([ // Note the order for best recommendations
            ...closestMatches, 
            ...nameClosestMatches, 
            ...nameAnywhereMatches, 
            ...anywhereMatches
        ])];
        setFilteredTickers(matches);
    }, [tickers]);

    // Filter the tickers whenever the query changes
    useEffect(() => {
        if (tickers === undefined) return;
        filterTickers(query);
    }, [query, filterTickers, tickers]);

    // Input handling
    const handleChange = (e) => {
        const value = e.target.value;
        setQuery(value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        // Add a small delay to ensure onClick events can still fire
        setTimeout(() => setIsFocused(false), 100);
    };

    return (
        <div className="relative">
            <input type="text" id="tickerSearchInput" className="w-full p-2 py-0 rounded outline outline-1 focus:rounded-b-none" 
                placeholder="Search for a ticker"
                value={query}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <ul id="tickerDropDown" className="absolute left-0 right-0 bg-white border border-gray-500 rounded rounded-t-none mt-0 max-h-48 overflow-y-auto z-10">
            {
                    isFocused && filteredTickers?.map(ticker => {
                        return (
                            <li key={ticker["ticker"]+ticker["name"]} className="p-2 hover:bg-gray-200 cursor-pointer flex-row justify-between items-center"
                                onClick={() => {onSelect(ticker); setQuery(""); setIsFocused(false);}}
                            >
                                <p>
                                    {ticker["ticker"]}
                                </p>
                                {
                                    // Check if the name will fit on one line, else use ellipsis
                                    ticker["name"].length > 20 ?
                                    <p className='text-xs'>
                                        {ticker["name"].substring(0, 20) + "..."}
                                    </p> :
                                    <p className='text-xs'>
                                        {ticker["name"]}
                                    </p>
                                }
                            </li>
                            
                        );
                    })
                }
                {
                    isFocused && tickers === undefined &&
                        (
                            <li className="p-2 hover:bg-gray-200 cursor-pointer flex-row justify-between items-center">
                                <p>
                                    Loading tickers...
                                </p>
                            </li>
                        )
                }
            </ul>
        </div>
    );
}

export { TickerInputs, TickerInputTable };
