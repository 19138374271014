import { GraphUp, PageEdit } from "iconoir-react";

export const EDTECH_PRODUCTS = [
    {
        name: "GoodPoint",
        subtitle: "AI Grader",
        Icon: PageEdit,
        features: [
            "Automatic grading",
            "Feedback generation",
            "Performance assessment"
        ],
        link: "/goodpoint"
    }
]

export const FINANCE_PRODUCTS = [
    {
        name: "NewsWitch",
        subtitle: "Tailored Financial Reports",
        Icon: GraphUp,
        features: [
            "News aggregation",
            "Sentiment analysis",
        ],
        link: "/newswitch"
    }
]