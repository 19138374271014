import React, { useState, useEffect } from 'react';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginRequest, silentRequest } from 'src/auth/AuthConfig';

/**
 * Only allows access to a route if the user is authenticated and has the required role and access level
 * By default, the access flag is required to access route 
 */
export const RouteGuard = ({ access = true, roles = [], ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(undefined);

    const currentAccount = instance.getActiveAccount();

    let authRequest = {
        ...loginRequest,
    };

    // Try a silent login if currentAccount is available
    if (currentAccount && currentAccount.idTokenClaims.emails && currentAccount.idTokenClaims.emails.length > 0) {
        authRequest = {
            ...silentRequest,
            loginHint: currentAccount.idTokenClaims.emails[0],
        }
    }

    useEffect(() => {

        // If there are no restrictions set, allow access
        if (currentAccount && roles.length === 0 && access === false) {
            setIsAuthorized(true);
            return;
        }

        // If roles passed to RouteGuard, check if user has a role that matches
        if (currentAccount && roles.length > 0) {
            if (!currentAccount.idTokenClaims['extension_Role']) {
                setIsAuthorized(false);
                return;
            }

            const claimRole = typeof currentAccount.idTokenClaims['extension_Role'] === 'string' ? currentAccount.idTokenClaims['extension_Role'] : "";
            let intersection = roles.filter((role) => claimRole.includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true); // This may be overriden by access flag
                if (access === false) {
                    return;
                }
            } else {
                setIsAuthorized(false);
                return;
            }
        }

        // If access flag is required, check if user has access
        if (currentAccount && access === true) {
            if (!currentAccount.idTokenClaims['extension_Active']) {
                setIsAuthorized(false);
                return;
            }
            const has_access  = typeof currentAccount.idTokenClaims['extension_Active'] == 'boolean' ? currentAccount.idTokenClaims['extension_Active'] : currentAccount.idTokenClaims['extension_Active'] === 'true';
            setIsAuthorized(has_access);
            return;
        }

        // Deny all other cases
        setIsAuthorized(false);
    }, [instance, currentAccount, access, roles, navigate, location.pathname]);

    useEffect(() => {
        if (isAuthorized === false && props.errorElement === undefined && location.pathname !== "/") {
                navigate("/");
        }
    }, [isAuthorized, props, location, navigate]);

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            {isAuthorized ? (
                <div>{props.children}</div>
            ) : props.errorElement === undefined ? <div><p>Unauthorized</p></div> : <div>{props.errorElement}</div>}
        </MsalAuthenticationTemplate>
    );
};

// Only show a component if conditions are met
export const ComponentGuard = ({ access = true, roles = [], errorElement = null, ...props }) => {
    return (
        <RouteGuard access={access} roles={roles} errorElement={errorElement}>
            {props.children}
        </RouteGuard>
    );
};
