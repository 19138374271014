import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import { JobsTable } from './JobsPage';
import { TickerInputTable } from './TickerInputs';
import { ReportList } from './ReportList';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function PublicConfigPage() {

    const [refreshKey, setRefreshKey] = useState(0);

    // Function to trigger a refresh of ReportsList when a job status changes
    const refreshReportsList = () => {
      setRefreshKey(prevKey => prevKey + 1);
    };

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="NewsWitch" href=".." />
                <Title 
                    title="Public Configuration"/>
                <div className='flex-col gap-4'>
                    <Link to="/finance" target="_blank" className='px-2 py-1 rounded outline outline-1 bg-orange-300 flex-row gap-4'>
                        <h3>View Latest Report</h3>
                    </Link>
                    <ConfigItem title="Jobs">
                        <JobsTable publicMode onJobStatusChange={refreshReportsList}/>
                    </ConfigItem>
                    <ConfigItem title="Tickers">
                        <TickerInputTable publicMode />
                    </ConfigItem>
                    <ConfigItem title="Past Reports">
                        <div className='max-h-52 overflow-y-scroll p-1'>
                            <ReportList publicMode key={refreshKey} />
                        </div>
                    </ConfigItem>
                </div>
            </div>
        </div>
    );
}

function ConfigItem({ title, children }) {
    return (
        <div className="flex-col gap-1">
            <hr />
            <h2>{title}</h2>
            {children}
        </div>
    );
}

export default PublicConfigPage;
