import { ArrowRight, Check } from "iconoir-react"
import { useNavigate } from 'react-router-dom';

import "./FooterControlBar.css"
import { capitalize } from "src/util/StringUtil"
import IconButton from "src/components/ui/IconButton";

function FooterRightButton({ text, Icon, onClick, enabled=true }) {
    return <IconButton className="next-button" 
        onClick={onClick} enabled={enabled} 
        Icon={Icon} iconLeft={false} text={text}
    />
}

function NextButton({ text, onClick, enabled=true }) {
    return <FooterRightButton text={text} Icon={ArrowRight} onClick={onClick} enabled={enabled} />
}

function FinishButton({ enabled=true }) {
    const navigate = useNavigate();
    return <FooterRightButton text={"Finish"} Icon={Check} onClick={() => { navigate("/goodpoint") }} enabled={enabled} />
}

export default function FooterControlBar({ changeTab, currentTab, nextTab, futureTab, footerControls=null }) {
    var nT, nextEnabled;
    if (currentTab === nextTab) {
        nT = futureTab;
        nextEnabled = false;
    } else {
        nT = nextTab;
        nextEnabled = true;
    }
    const nextButton = (nT != null)
        ? (<NextButton text={`Next: ${capitalize(nT)}`} onClick={() => changeTab(nT)} enabled={nextEnabled}/>)
        : (<FinishButton enabled={nextEnabled}/>)
    return (
        <div className="footer-container centered">
            <div className="assessment-footer container justify-content-space-between flex-row align-center">
                <div className="left-controls flex-row gap-mid">
                    {footerControls}
                </div>
                {nextButton}
            </div>
        </div>
    )
}