
import { Collapse, Expand } from "iconoir-react";
import { useContext, useEffect, useState } from "react";
import { useCollapse } from "react-collapsed";
import Latex from "react-latex-next";
import { useFetchGeneralFeedback } from "src/api/goodpoint/useFetchFeedback";
import IconButton from "src/components/ui/IconButton";
import { AssessmentContext } from "src/pages/goodpoint/assessment/AssessmentContext";
import { AiGenerated } from "src/pages/goodpoint/assessment/components/structure/StructureSection";


export default function ClassGeneralFeedback() {
    const {assessmentObject} = useContext(AssessmentContext);

    const fetchGeneralFeedback = useFetchGeneralFeedback(assessmentObject.id);
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        collapsedHeight: 120,
        hasDisabledAnimation: true
    });

    const [classFeedback, setClassFeedback] = useState(null);
    useEffect(() => {
        if (classFeedback) return;
        const result = fetchGeneralFeedback();
        setClassFeedback(result);
    }, [classFeedback, fetchGeneralFeedback]);

    return (
        <div className="class-feedback">
            <div className="feedback-header flex-row justify-content-space-between align-center">
                <div className="flex-row section-heading-left gap-small">
                    <b>Class Feedback</b>
                    <AiGenerated />
                </div>
                <div className="section-heading-controls">
                    <IconButton
                        Icon={isExpanded ? Collapse : Expand}
                        text={isExpanded ? "Collapse" : "Expand"}
                        bgColour={isExpanded ? "var(--orange-mid)" : "var(--yellow-light)"}
                        paddingSize="2px 5px"
                        borderSize="var(--border-thin)"
                        {...getToggleProps()}
                    />
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className={`${isExpanded ? "" : "fade-paragraph"}`}>
                    { classFeedback && <Latex>{classFeedback}</Latex> }
                </div>
            </div>
        </div>
    );
}