import { useCallback, useState } from "react";
import useCallApi from "src/api/useCallApi";

export function useGeneralBlobDownload() {
    const callApi = useCallApi();
    const [isDownloading, setIsDownloading] = useState(false);

    function download(blobEndpoint, downloadFilename) {
        setIsDownloading(true);
        return callApi("GET", blobEndpoint)
            ?.then(async response => {
                if (response.status === 200) {
                    // Get the blob data
                    const blob = await response.blob();
                    // Download blob as a file from a link
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = downloadFilename;
                    document.body.appendChild(link);
                    link.click();
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error(
                        `Failed to download ${downloadFilename}: ${response.status} ${response.statusText} \n` +
                        `from (GET) ${blobEndpoint}`
                    );
                }
            })
            .finally(() => {
                setIsDownloading(false);
            });
    }

    return {
        "download": useCallback(download, [callApi]),
        isDownloading
    };
}

export function useBlobDownload(blobEndpoint, downloadFilename) {
    const {download, isDownloading} = useGeneralBlobDownload();
    return {
        "download": () => download(blobEndpoint, downloadFilename),
        isDownloading
    };
}