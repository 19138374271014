import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import { useEffect, useState } from 'react';
import { protectedResources } from 'src/auth/AuthConfig';
import { useLocation } from 'react-router-dom';
import { capitalize } from "src/util/StringUtil";
import { Report, DatabaseReport } from 'src/pages/newswitch/components/Report';
import useNewsWitchApi from 'src/api/newswitch/useNewsWitchApi';
import { useMsal } from '@azure/msal-react';

// Remove the header and title section from the report
const removeHeader = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const body = doc.body;
    const header = body.querySelector('.header');
    if (header) header.remove();
    return body.innerHTML;
}

// Remove the header and title section from the report
const removeTitle = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const body = doc.body;
    const titlePage = body.querySelector('.title-page');
    if (titlePage) titlePage.remove();
    return body.innerHTML;
}

function DailyReport() {
    // Once isLoading is false, we can call the API using callAPI
    const callAPI = useNewsWitchApi();

    const [run, setRun] = useState(undefined);

    useEffect(() => {
        callAPI("GET", "/runs")?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const runs = Object.values(body["runs"])
                    // Find the run with the given ID
                    if (runs.length === 0) {
                        console.error("No runs found");
                        setRun({});
                        return;
                    }
                    setRun(runs[0]);
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="NewsWitch" href=".." />
                <Title 
                    title="NewsWitch"
                    subtitle={"Latest daily report"}
                    
                />
                <DatabaseReport runID={run?.id} />
            </div>
        </div>
    );
}

function ReportPage({ publicMode = undefined }) {
    // Once isLoading is false, we can call the API using callAPI
    const callAPI = useNewsWitchApi();

    const location = useLocation();
    const prevPage = location?.state?.prevPage;
    publicMode = publicMode || location?.state?.publicMode;

    const [reportHTML, setReportHTML] = useState(undefined);

    const urlParams = new URLSearchParams(window.location.search);
    const blobPath = urlParams.get("blob");
    const runID = urlParams.get("run_id");
    console.log("ReportPage: blobPath=" + blobPath + ", runID=" + runID);

    useEffect(() => {
        if (!blobPath) return;
        const endpoint = publicMode ? "/public/report" : "/report";
        callAPI("GET", `${endpoint}?blob_path=` + blobPath)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const reportHTML = atob(body["report"]);
                    setReportHTML(removeTitle(reportHTML));
                });
            } else {
                console.error(response);
                setReportHTML("<div>Failed to load report</div>");
            }
        });
    }, [callAPI, publicMode, blobPath]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink 
                    prevPage={prevPage ? capitalize(prevPage.split("/").pop()) : "NewsWitch"}
                    href={prevPage ? prevPage : ".."} />
                <Title 
                    title="Daily Report"
                    subtitle="NewsWitch: Tailored Financial Newsletter"
                    
                />
                { blobPath ? <Report reportHTML={reportHTML} /> : runID && <DatabaseReport runID={runID} /> }
            </div>
        </div>
    );
}

function PublicReport() {
    const { instance } = useMsal();

    const [reportHTML, setReportHTML] = useState(undefined);
    const isLoggedIn = instance.getActiveAccount() !== null;

    console.log("PublicReport: isLoggedIn=" + isLoggedIn);

    useEffect(() => {
        const endpoint = "/public/latest" + (isLoggedIn ? "" : "/snippet");
        fetch(protectedResources.apiNewsWitch.endpoint + endpoint, {
            method: 'GET',
            mode: 'cors',
        }).then(response => {
            console.log(response);
            if (response.status === 200) {
                response.json().then(body => {
                    const reportHTML = atob(body["report"]);
                    setReportHTML(removeTitle(removeHeader(reportHTML)));
                });
            } else {
                console.error(response);
                setReportHTML("<div>Check back later for the latest report</div>");
            }
        });
    }, [isLoggedIn]);

    return (
        <div className="centered">
            <div className="container">
                <Title 
                    title="NewsWitch"
                    subtitle={"Tailored Financial Newsletter"}
                    
                />
                <Report reportHTML={reportHTML} snippet={!isLoggedIn} clarityMask='False' />
            </div>
        </div>
    );
}

export { DailyReport, ReportPage, PublicReport };
