import { useState, useContext, useEffect } from "react";
import { AssessmentItem } from "src/pages/goodpoint/dashboard/AssessmentItem";
import Divider from "src/components/layout/Divider";
import { EditPencil } from "iconoir-react";
import { CheckCircle } from "iconoir-react";
import dayjs from "dayjs";
import DeleteAssessmentButton from "src/pages/goodpoint/dashboard/DeleteAssessmentButton";
import { AssessmentsListContext } from "src/pages/goodpoint/dashboard/AssessmentsListContext";

import "./AssessmentMenu.css";

function AssessmentMenuHeader({ title, editMode, editFunction }) {
    return (
        <div className="flex-row justify-content-space-between">
            <h2>{title}</h2>
            {
                editFunction &&
                <button className="edit-button padding-none" onClick={editFunction}>
                    {!editMode ? <EditPencil /> : <CheckCircle />}
                </button>
            }
        </div>
    )
}

export default function AssessmentMenu({ title, filter }) {
    const { assessments } = useContext(AssessmentsListContext);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        // Stop editing after we delete an exam
        setEditMode(false);
    }, [assessments]);

    const items = Object.values(assessments)?.filter(filter) ?? [];
    if (items.length === 0) {
        return null
    }

    return (
        <div className='assessment-menu'>
            <AssessmentMenuHeader title={title} editMode={editMode} editFunction={items.length > 0 && (() => setEditMode(!editMode))} />
            <Divider/>
            <div className="flex-col gap-large">
                {
                    items.sort((a, b) => dayjs(a.date).isBefore(b.date) ? -1 : 1).map((item, index) => (
                        <div className="flex-row">
                            <AssessmentItem
                                key={`${title} ${item.id} ${index}`}
                                item={item}
                                clickable={!editMode}
                            />
                            {
                                editMode && <DeleteAssessmentButton assessmentItem={item} />
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}