import React, { useEffect, useState, useRef } from 'react';
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import useCallApi from 'src/api/useCallApi';
import { protectedResources } from 'src/auth/AuthConfig';

function UserManagementPage() {

    const callAPI = useCallApi();

    const [tempUserEmail, setTempUserEmail] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [searchHistory, setSearchHistory] = useState([]);
    const [timestamp, setTimestamp] = useState(0);
    const [userOverview, setUserOverview] = useState(undefined);

    const searchBoxRef = useRef(null);

    useEffect(() => {
        callAPI("GET", protectedResources.apiManagement.endpoint + "/user-overview")?.then((response) => {
            if (response.status !== 200) {
                return;
            }
            response.json().then(response => 
                setUserOverview(response)
            );
        });
    }, [callAPI]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Admin Portal" href="/admin" />
                <Title 
                    title="User Management"
                    subtitle={userOverview !== undefined && `Active Accounts: ${userOverview["user_count"]}`}
                />
                <div className="flex-col gap-8">
                    <div className='flex-row gap-2'>
                        <input 
                            type="text" 
                            ref={searchBoxRef}
                            placeholder="Search by email" 
                            className="p-2 rounded outline outline-1 bg-white w-full" 
                            onChange={(e) => setTempUserEmail(e.target.value)}
                        />
                        {
                            // dropdown of search history
                            searchHistory.length > 0 &&
                            <select 
                                className="p-2 rounded outline outline-1 bg-white"
                                onChange={(e) => {
                                    if (e.target.value === "") return;
                                    setTempUserEmail(e.target.value);
                                    searchBoxRef.current.value = e.target.value;
                                }}
                            >
                                <option value="">Search History</option>
                                {
                                    searchHistory.map((email, index) => {
                                        return <option key={index} value={email}>{email}</option>
                                    })
                                }
                            </select>
                        }
                        <button 
                            className="p-2 rounded outline outline-1 bg-orange-300"
                            onClick={() => {
                                    setUserEmail(tempUserEmail)
                                    if (!searchHistory.includes(tempUserEmail)) {
                                        setSearchHistory([tempUserEmail, ...searchHistory]);
                                    }
                                    setTimestamp(Date.now())
                                }
                            }
                        >
                            Search
                        </button>
                    </div>
                    { userOverview &&
                        <div className='grid grid-cols-2 font-bold'>
                            <p>
                                Active Accounts: {userOverview["user_count"]}
                            </p>
                            <p>
                                EU Accounts: {userOverview["eu_user_count"]}
                            </p>
                            <p>
                                Deleted Accounts: {userOverview["deleted_users"]}
                            </p>
                            <p>
                                US Accounts: {userOverview["us_user_count"]}
                            </p>
                        </div>
                    }
                    <UserInfoSection email={userEmail} ts={timestamp}/>
                </div>
            </div>
        </div>
    );
}

function UserInfoSection({ email, ts }) {

    const callAPI = useCallApi();

    const keys = [
        "display_name",
        "first_name",
        "last_name",
        "email",
        "id",
        "b2c_id",
        "job_title",
        "role",
    ]

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (ts < 0) return;
        if (!email || email === "" || !email.includes("@")) return;
        setIsLoading(true);
        callAPI("GET", protectedResources.apiManagement.endpoint + `/user?email=${email}`)?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to fetch user data: " + response.status + " " + response.statusText);
                setUserData(null)
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => setUserData(response));
                setIsLoading(false);
            }
        });
    }, [callAPI, email, ts]);

    const changeAccess = (access) => {
        setIsLoading(true);
        callAPI("POST", protectedResources.apiManagement.endpoint + "/user/update", {
            body: {
                user_id: userData.id,
                access: access
            }
        })?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to update user: " + response.status + " " + response.statusText);
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => setUserData(response));
                setIsLoading(false);
            }
        });
    }

    const deleteUser = () => {
        if (window.confirm("Are you sure you want to delete this user?") === false) return;
        setIsLoading(true);
        callAPI("DELETE", protectedResources.apiManagement.endpoint + "/user", {
            body: {
                user_id: userData.id
            }
        })?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to delete user: " + response.status + " " + response.statusText);
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                setUserData(null);
                setIsLoading(false);
            }
        });
    }

    return (
            isLoading ? 
                <p>Loading user data...</p> 
            : (
                userData ?
                <div className="flex-col gap-4">
                    <div>
                        {
                            userData && keys.filter((key) => key !== "access").map((key) => {
                                return (
                                    <div className="grid grid-cols-2 gap-2">
                                        <p key={key + "key"}>
                                            {key}:
                                        </p>
                                        <p key={key + "value"}>
                                            {
                                            typeof userData[key] === "boolean" ? userData[key] ? "true" : "false" : userData[key]
                                            }
                                        </p>
                                    </div>
                                );
                            })
                        }
                        <div className="grid grid-cols-2 gap-2">
                            <p>
                                access:
                            </p>
                            <p>
                                {userData["active"] ? "true" : "false"}
                            </p>
                        </div>
                    </div>
                    <div className="flex-row gap-4">
                        <button 
                            className="p-2 rounded outline outline-1 bg-red-300"
                            onClick={deleteUser}
                        >
                            Delete User
                        </button>
                        <button 
                            className="p-2 rounded outline outline-1 bg-orange-300"
                            onClick={() => changeAccess(userData.active ? false : true)}
                        >
                            {userData.active ? "Remove" : "Give"} Access
                        </button>
                    </div>
                </div>
            : <p>{errorMessage}</p>
        )
    );
}

export default UserManagementPage;