import './InfoTab.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Title from 'src/components/content/Title';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCallback, useContext, useLayoutEffect } from 'react';
import { AssessmentContext } from 'src/pages/goodpoint/assessment/AssessmentContext';
import GradeBoundaries from 'src/pages/goodpoint/assessment/tabs/info/GradeBoundaries';
import useSendConfig from 'src/api/goodpoint/useSendConfig';
import { createTheme, ThemeProvider } from '@mui/material';


const PLACEHOLDERS = {
    institute: "(institute)",
    department: "(department)",
    course_title: "(course title)",
    course_level: "(course level)",
    subject: "(subject)",
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#F49569",
            dark: "#EF753D"
        },
        secondary: {
            main: "#F49569"
        }
    },
});

function FormItem({ label, dontCenterLabel=false, children }) {
    return (
        <div className={`form-item ${!dontCenterLabel ? "align-center" : ""}`}>
            <p>{label}</p>
            <div>
                {children}
            </div>
        </div>
    )
}

function InfoTab() {
    const {assessmentObject, updateAssessmentObject, setFooterControls} = useContext(AssessmentContext);
    const sendConfig = useSendConfig(assessmentObject.id, updateAssessmentObject);
    
    useLayoutEffect(() => {
        setFooterControls(null);
    }, [setFooterControls]);

    const updateConfig = useCallback((updates) => {
        updateAssessmentObject({
            exam_config: {
                ...assessmentObject.exam_config,
                ...updates
            }
        });
        sendConfig(updates);
    }, [assessmentObject.exam_config, sendConfig, updateAssessmentObject]);

    function ConfigInput({ type="text", configKey, valueFunc=null, children=null }) {
        const props = {};
        const currentValue = assessmentObject.exam_config[configKey];
        const isPlaceholder = currentValue === PLACEHOLDERS[configKey];
        if (isPlaceholder) {
            props["placeholder"] = currentValue;
        } else {
            props["defaultValue"] = currentValue;
        }

        var Control = "input";
        if (type === "select") {
            Control = "select";
            props["children"] = children;
        }

        return (
            <Control className={`form-item-content`}
                type={type}
                onBlur={(e) => {
                    var value = e.target.value;
                    if (!value) {
                        value = PLACEHOLDERS[configKey];
                        e.target.value = value;
                    }
                    updateConfig({
                        [configKey]: valueFunc ? valueFunc(e.target.value) : e.target.value
                    });
                }}
                {...props}
            />
        )
    }

    return (
        <div className='assessment-info'>
            <Title
                Size='h2'
                title="Info"
                subtitle="Configure the information for this assessment"
            />
            <form onSubmit={(event) => event.preventDefault()}>
                <FormItem label="Institute">
                    <ConfigInput configKey={"institute"}/>
                </FormItem>
                <FormItem label="Department">
                    <ConfigInput configKey={"department"}/>
                </FormItem>
                <FormItem label="Course Title">
                    <ConfigInput configKey={"course_title"}/>
                </FormItem>
                <FormItem label="Course Level">
                    <ConfigInput configKey={"course_level"}/>
                </FormItem>
                <FormItem label="Subject">
                    <ConfigInput configKey={"subject"}/>
                </FormItem>
                <FormItem label="Date">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={theme}>
                            <DatePicker
                                format='DD MMM YYYY'
                                defaultValue={
                                    (assessmentObject.exam_config.date === null) 
                                        ? dayjs()
                                        : dayjs(assessmentObject.exam_config.date).set('hour', 12)
                                }  
                                onChange={(event) => updateConfig({date: event.toDate()})}
                            />
                        </ThemeProvider>
                    </LocalizationProvider>
                </FormItem>
                <FormItem label="Submission Type">
                    <ConfigInput type="select" configKey={"submission_type"}>
                        <option>Handwritten</option>
                        <option>Typed</option>
                    </ConfigInput>
                </FormItem>
                <FormItem label="Suggested Marks">
                    <ConfigInput type="number" configKey={"max_marks"} valueFunc={parseInt}/>
                </FormItem>
                <FormItem label="Grade Boundaries" dontCenterLabel>
                    <GradeBoundaries updateExamConfig={updateConfig} />
                </FormItem>
            </form>
        </div>
    );
}

export default InfoTab;
