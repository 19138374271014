
import useQueryParam from "src/api/useQueryParams";
import { useEffect, useState } from "react";
import { TermsOfUse } from "./TermsOfUse";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Markdown from 'react-markdown'
import { ContactPopup, Subject } from "src/pages/home/components/ContactPopup";

const Tabs = {
    TERMS: "terms",
    PRIVACY: "privacy"
}

function Legal() {
    const [tab, setTab] = useQueryParam("tab", Tabs.TERMS);
    const [selected, setSelected] = useState(tab === Tabs.TERMS);
    const location = useLocation();

    useEffect(() => {
        setSelected(tab === Tabs.TERMS);
    }, [tab]);

    // On location change ensure our tab is updated
    useEffect(() => {
        setTab(location.search.includes("tab="+Tabs.PRIVACY)  ? Tabs.PRIVACY : Tabs.TERMS);
    }, [location, setTab]);

    return (
        <div className='w-full'>
            <div className="flex-row justify-around px-2 py-4">
                <TabItem title="Terms of Use" to={"/legal?tab=terms"} selected={selected} />
                <TabItem title="Privacy Policy" to={"/legal?tab=privacy"} selected={!selected} />
            </div>
            <div className="p-2">
                {
                    tab === Tabs.TERMS ? <RenderMarkdown text={TermsOfUse} /> : <RenderMarkdown text={PrivacyPolicy} />
                }
            </div>
        </div>
    );
}

function TabItem({ title, to, selected}) {
    return (
        <Link to={to} className={"outline cursor-pointer outline-1 w-full text-center py-2 " + (selected ? "bg-orange-200 font-bold" : "bg-gray-50 font-normal")}>
            <p>{title}</p>
        </Link>
    );
}

function RenderMarkdown({ text }) {
    return (
        <div className="flex-col gap-2">
            <Markdown
                components={{
                    h1(props) {
                        const {node, ...rest} = props
                          return <p className="sm:text-lg text-base text-black" {...rest} />
                    },
                    h2(props) {
                        const {node, ...rest} = props
                        return <p className="text-base font-bold text-gray-700" {...rest} />
                    },
                    p(props) {
                        const {node, ...rest} = props
                        return <p className="text-xs sm:text-sm text-gray-700" {...rest} />
                    },
                    // Bold
                    strong(props) {
                        const {node, ...rest} = props
                        return <b className="text-xs sm:text-sm font-bold text-gray-700" {...rest} />
                    },
                    // Italics
                    em(props) {
                        const {node, ...rest} = props
                        return <p className="text-xs sm:text-sm italic text-gray-700" {...rest} />
                    },
                    // Links
                    a(props) {
                        const {node, ...rest} = props
                        return (
                            rest.href.toLowerCase() === "mailto:info@zanista.ai" ?
                                <ContactPopup subject={Subject.GENERAL}>
                                    <span className="text-xs sm:text-sm underline cursor-pointer text-gray-700" {...rest} />
                                </ContactPopup>
                            :
                                <Link to={rest.href} className="text-xs sm:text-sm underline cursor-pointer text-gray-700" {...rest} />
                        )   
                    },
                    li(props) {
                        const {node, ...rest} = props
                        return (<div className="pl-2 py-1 gap-2 flex-row items-center">
                                    <div className="bg-gray-700 w-1 h-1 rounded-full"></div>
                                    <div className="" {...rest} />
                                </div>)
                    },
                  }}
            >
                {text}
            </Markdown>
        </div>
    );
}

export default Legal;
