import { useMemo } from "react";
import Loading from "src/components/ui/Loading";
import { ExamStructure } from "src/pages/goodpoint/assessment/components/structure/ExamStructure";
import StudentFeedbackItem from "src/pages/goodpoint/assessment/tabs/grading/student-performance/student-feedback/StudentFeedbackItem";


export default function StudentFeedbackStructure({ 
    studentFeedback, 
    isFilterOnlyMistakes = false, 
    isFilterOnlyFlagged = false 
}) {
    const numAnswers = useMemo(() => {
        return Object.keys(studentFeedback ?? {}).length;
    }, [studentFeedback]);

    function Header() {
        return (
            <div className="flex-row align-center gap-large">
                <p>{numAnswers} Question{(numAnswers > 1) ? "s" : ""} Answered</p>
            </div>
        )
    }

    if (!studentFeedback) {
        return <Loading />
    }

    return (
        <ExamStructure
            structureMap={studentFeedback}
            HeaderComponent={Header}
            ItemComponent={StudentFeedbackItem}
            initiallyExpanded={true}
            itemCommonProps={{isFilterOnlyMistakes, isFilterOnlyFlagged}}
        />
    )
}